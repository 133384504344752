import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Pencil } from 'lucide-react';

import { useGetDataTables } from '../../hooks/useDataTablesHook';
import {
  useGetScenarioById,
  useGetScenarios,
  useGetScenarioVectors,
} from '../../hooks/useScenariosHook';
import cn from '../../utils/cn';

type DataRow = {
  id: string;
  inputTable: string;
  scenario: number | null;
  vectors?: string[];
};

const columnHelper = createColumnHelper<DataRow>();

const columns = () => [
  columnHelper.accessor('inputTable', {
    cell: (info) => <div className="py-2 text-start">{info.getValue()}</div>,
    header: () => (
      <div className="flex items-center gap-2">
        <p>Input table</p>
      </div>
    ),
  }),
  columnHelper.accessor('scenario', {
    id: 'scenario',
    cell: (info) => {
      const { org_id, project_id, model_id } = useParams();

      const scenarioId = Number(info.getValue());
      const orgId = Number(org_id);
      const projectId = Number(project_id);
      const modelId = Number(model_id);

      const { data: scenario } = useGetScenarioById(
        {
          scenarioId,
          modelId,
          projectId,
          orgId,
        },
        {
          enabled: !!scenarioId && !!modelId && !!projectId && !!orgId,
        }
      );

      if (!scenario) {
        return <div className="text-gray-400">--</div>;
      }

      return <div>{scenario?.name}</div>;
    },
    header: () => <div>Scenario</div>,
  }),
  columnHelper.accessor('vectors', {
    id: 'vectors',
    cell: (info) => {
      const id = info.row.original.id;
      const scenarioId = Number(info.row.original.scenario);

      const { model_id, project_id, org_id } = useParams();

      const modelId = Number(model_id);
      const projectId = Number(project_id);
      const orgId = Number(org_id);

      const { data: vectors } = useGetScenarioVectors(
        {
          scenarioId,
          modelId,
          projectId,
          orgId,
        },
        {
          enabled: !!scenarioId && !!modelId && !!projectId && !!orgId,
        }
      );

      const navigate = useNavigate();

      const handleOpenEdit = () => {
        navigate(`input-table/${id}`);
      };

      return (
        <div className="flex justify-between">
          <p>
            {vectors?.length
              ? vectors?.map((vector) => vector.name).join(', ')
              : '--'}
          </p>
          <Pencil onClick={handleOpenEdit} size={16} />
        </div>
      );
    },
    header: () => <div>Vectors</div>,
  }),
];

const ScenarioInputTable = () => {
  const { project_id, org_id, model_id } = useParams();

  const orgId = Number(org_id);

  const modelId = Number(model_id);
  const projectId = Number(project_id);

  const { data: scenarios } = useGetScenarios(
    {
      modelId,
      orgId,
      projectId,
    },
    {
      enabled: !!modelId && !!orgId && !!projectId,
    }
  );

  const { data: dataTables } = useGetDataTables(
    {
      orgId,
      projectId,
    },
    {
      enabled: !!projectId && !!orgId,
    }
  );

  const data = useMemo(
    () =>
      dataTables
        ? dataTables.map((dataTable) => {
            const scenario = scenarios?.find(
              (s) => s.data_table_name === dataTable.name
            );

            return {
              id: dataTable.name,
              inputTable: dataTable.display_name ?? dataTable.name ?? 'Unknown',
              scenario: Number(scenario?.id),
            };
          })
        : [],
    [scenarios, dataTables]
  );

  const table = useReactTable({
    data,
    columns: columns(),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <table className="w-full">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className="!rounded-md border border-[#E4E7EC] bg-[#F8F8F8]"
            >
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={cn(
                    'border border-[#E4E7EC] px-6 py-2 text-start text-sm text-[#4D4D4D]'
                  )}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="cursor-pointer px-6 py-2">
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="border border-[#E4E7EC] px-6 text-[#333]"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ScenarioInputTable;
