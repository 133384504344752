import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Checkbox, Collapse } from '@mui/material';
import _ from 'lodash';

import { useGetModelById } from '../../hooks';

type CheckboxesCollapseProps = {
  name: string;
  title: string;
  group?: 'default' | 'user';
  items: {
    label: string;
    value: string;
  }[];
};

const CheckboxesCollapse = ({
  title,
  name,
  items,
  group = 'default',
}: CheckboxesCollapseProps) => {
  const [open, setOpen] = useState(true);
  const [checkedAll, setCheckAll] = useState(false);

  const { org_id, project_id, model_id } = useParams();

  const orgId = Number(org_id);
  const modelId = Number(model_id);
  const projectId = Number(project_id);

  const { data: model } = useGetModelById(
    {
      modelId,
      orgId,
      projectId,
    },
    {
      enabled: !!orgId && !!projectId && !!modelId,
    }
  );

  const { register, setValue, getValues } = useFormContext();

  const defaultName = `${group}.${name}`;

  const data = getValues(defaultName);

  const isIndeterminate =
    !checkedAll && Object.keys(data ?? {}).some((key) => data[key]);

  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const preDefinedExpenses = model?.predefined_expenses ?? [];

    const defaultValues = items.reduce(
      (acc, cur) => {
        acc[cur.value] = preDefinedExpenses.includes(Number(cur.value));
        return acc;
      },
      {} as Record<string, boolean>
    );

    if (_.isEqual(data, defaultValues) || !defaultValues || data) {
      return;
    }
    setValue(defaultName, defaultValues);
  }, [defaultName, items, model]);

  useEffect(() => {
    const isCheckedAll = items.every((item) => data?.[item?.value]);

    setCheckAll(isCheckedAll);
  }, [data, items]);

  const handleToggleAll = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();

    const isChecked = !isIndeterminate && (e.target as any).checked;

    setCheckAll(isChecked);

    const all = items.reduce(
      (acc, key) => {
        acc[key.value] = isIndeterminate ? false : isChecked;
        return acc;
      },
      {} as Record<string, boolean>
    );

    setValue(defaultName, all);
  };

  return (
    <div className="px-6">
      <button
        type="button"
        onClick={handleToggle}
        className="flex items-center gap-1 text-[#333]"
      >
        {open ? <ArrowDropUp /> : <ArrowDropDown />}
        <Checkbox
          checked={checkedAll}
          onClick={handleToggleAll}
          indeterminate={isIndeterminate}
        />
        <label className="text-lg font-bold capitalize">{title}</label>
      </button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className="border-gray-300 mb-4 ml-12 flex flex-col border-l pl-3">
          {data &&
            items.map((expenseSet) => (
              <div key={expenseSet.value} className="flex justify-between">
                <div className="flex items-center gap-1">
                  <Checkbox
                    id={expenseSet.value}
                    checked={data[expenseSet.value] || false}
                    {...register(`${defaultName}.${expenseSet.value}`)}
                  />
                  <label htmlFor={expenseSet.value}>{expenseSet.label}</label>
                </div>
              </div>
            ))}
        </div>
      </Collapse>
    </div>
  );
};

export default CheckboxesCollapse;
