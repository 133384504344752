import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';

import { MenuItem } from '@mui/material';
import { EllipsisVertical } from 'lucide-react';

import {
  useDeleteScenarioById,
  useGetScenariosByDataTableName,
} from '../../hooks/useScenariosHook';
import cn from '../../utils/cn';
import { arrangeByBase } from '../../utils/helpers';
import MenuDropdown from '../MenuDropdown';
import CreateScenarioFormDialog from '../scenario/CreateScenarioFormDialog';
import SearchInput from '../SearchInput';
import ConfirmDeleteScenarioDialog from '../SimpleConfirmDeleteDialog';

const InputDataDrawerSection = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const [searchParams, setSearchParams] = useSearchParams();
  const { input_table_name, org_id, project_id, model_id } = useParams();

  const scenarioId = Number(searchParams.get('scenario_id'));

  const modelId = Number(model_id);
  const orgId = Number(org_id);
  const projectId = Number(project_id);

  const [openDialogCreateScenario, setOpenDialogCreateScenario] =
    useState(false);

  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const form = useForm();

  const { data: scenarios } = useGetScenariosByDataTableName(
    {
      modelId,
      orgId,
      projectId,
      dataTableName: input_table_name ?? '',
    },
    {
      enabled: !!modelId && !!orgId && !!projectId && !!input_table_name,
    }
  );

  const { mutate: deleteScenario } = useDeleteScenarioById();

  useEffect(() => {
    if (!scenarioId && scenarios) {
      searchParams.set('scenario_id', scenarios[0]?.id.toString());
      setSearchParams(searchParams);
    }
  }, [scenarioId, scenarios]);

  const handleCloseDropdown = () => setAnchorEl(null);

  const handleOpenConfirmDeleteDialog = () => setOpenConfirmDeleteDialog(true);

  const handleOpenDialogCreateScenario = () => {
    setOpenDialogCreateScenario(true);
  };

  const handleCloseDialogCreateScenario = () => {
    setOpenDialogCreateScenario(false);
  };

  const handleCloseConfirmDeleteDialog = () =>
    setOpenConfirmDeleteDialog(false);

  const handleSelect = (scenarioId: number) => {
    searchParams.set('scenario_id', scenarioId.toString());

    setSearchParams(searchParams);
  };

  const handleDelete = async () => {
    if (!scenarioId || !orgId || !projectId || !modelId) {
      return;
    }

    deleteScenario({
      orgId,
      projectId,
      scenarioId,
      modelId,
    });
  };

  return (
    <FormProvider {...form}>
      <div className="flex flex-col gap-3 px-3 py-6">
        <form className="flex flex-col gap-3">
          <div className="flex items-center justify-between">
            <span className="w-full text-lg font-semibold text-[#E8F2FF]">
              Scenario
            </span>
            <CreateScenarioFormDialog
              title="Create New Scenario"
              open={openDialogCreateScenario}
              handleCloseDialog={handleCloseDialogCreateScenario}
              handleOpenDialog={handleOpenDialogCreateScenario}
            />
          </div>
          <SearchInput className="border-none bg-[#21438C] text-[#E8F2FF] placeholder-[#E8F2FF]" />
        </form>
        <div className="py-1">
          {arrangeByBase(scenarios)?.map((s) => (
            <button
              type="button"
              onClick={() => handleSelect(s.id)}
              className={cn(
                'flex text-[#E8F2FF] items-center transition w-full rounded-[4px] px-3 py-2 justify-between',
                {
                  'bg-[#21438C]': s.id === Number(scenarioId),
                }
              )}
            >
              <p className="max-w-[85%] truncate">{s.name}</p>
              {Number(scenarioId) === s.id && !s.is_base && (
                <div>
                  <MenuDropdown
                    trigger={
                      <button type="button">
                        <EllipsisVertical width={20} height={20} />
                      </button>
                    }
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    style={{
                      marginTop: '5px',
                      marginLeft: '-5px',
                    }}
                    open={open}
                  >
                    <MenuItem
                      onClick={() => {
                        handleCloseDropdown();
                        handleOpenDialogCreateScenario();
                      }}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleCloseDropdown();
                        // handleCloneModel(s.id);
                      }}
                    >
                      Duplicate
                    </MenuItem>
                    <MenuItem>Archive</MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleCloseDropdown();
                        handleOpenConfirmDeleteDialog();
                      }}
                    >
                      Delete
                    </MenuItem>
                  </MenuDropdown>
                </div>
              )}
            </button>
          ))}
        </div>
      </div>
      <ConfirmDeleteScenarioDialog
        open={openConfirmDeleteDialog}
        handleOnClose={handleCloseConfirmDeleteDialog}
        handleOnSubmit={handleDelete}
      />
    </FormProvider>
  );
};

export default InputDataDrawerSection;
