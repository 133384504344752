import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useCurrentOrganization } from '../../utils/helpers';
import InviteFormDialog from '../organizations/InviteFormDialog';
import SearchInput from '../SearchInput';
import UsersTable from '../UsersTable';

const roles = [
  {
    label: 'Account Admin',
    value: 'organization_admin',
  },
  {
    label: 'Project Owner',
    value: 'project_owner',
  },
  {
    label: 'Project Member',
    value: 'project_member',
  },
];

const UserManagement = () => {
  const [openInviteFormDialog, setOpenInviteFormDialog] = useState(false);

  const currentOrg = useCurrentOrganization();

  const form = useForm();

  const isOrgAdmin = currentOrg?.role === 'organization_admin';

  const handleOpenInviteFormDialog = () => {
    setOpenInviteFormDialog(true);
  };

  const handleCloseInviteFormDialog = () => {
    setOpenInviteFormDialog(false);
  };

  return (
    <div className="mx-auto flex max-w-lg flex-col space-y-6 px-2 py-6">
      <div className="flex w-full justify-between">
        <div className="flex flex-col gap-2">
          <h1 className="text-2xl font-bold text-[#333]">User Management</h1>
          <p className="text-[#666]">Coming soon.</p>
        </div>
        <div>
          {isOrgAdmin && (
            <InviteFormDialog
              roles={roles}
              open={openInviteFormDialog}
              handleClose={handleCloseInviteFormDialog}
              handleOpen={handleOpenInviteFormDialog}
            />
          )}
        </div>
      </div>
      <div className="flex gap-6">
        <div className="flex flex-col border border-[#E4E7EC] px-6 pt-3">
          <p className="w-[118px] text-[#666666]">Total users</p>
          <p className="text-4xl font-semibold text-primary-red">-</p>
        </div>

        <div className="flex flex-col border border-[#E4E7EC] px-6 pt-3">
          <p className="w-[118px] text-[#666666]">Active users</p>
          <p className="text-4xl font-semibold text-primary-red">-</p>
        </div>

        <div className="flex flex-col border border-[#E4E7EC] px-6 pt-3">
          <p className="w-[118px] text-[#666666]">Account Admins</p>
          <p className="text-4xl font-semibold text-primary-red">-</p>
        </div>
      </div>
      <FormProvider {...form}>
        <form className="w-[300px]">
          <SearchInput />
        </form>
      </FormProvider>
      <div className="w-full">
        <UsersTable />
      </div>
    </div>
  );
};

export default UserManagement;
