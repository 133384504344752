import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import _ from 'lodash';
import * as z from 'zod';

import { toastPromise } from '../../common/utils';
import { useGetDatasets, useUpdateModel } from '../../hooks';
import { useGetPreDefinedExpenses } from '../../hooks/useExpenseHook';
import { ExpenseCollapse } from '../drawer/ExpenseDrawerSection';
import {
  PredefinedExpensesForm,
  UserDefinedExpensesForm,
} from '../expense/CreateExpenseForm';
import FormInput from '../FormInput';
import FormSelect from '../FormSelect';

const DatasetConfigurationSchema = z.object({
  label: z.string(),
  value: z.string(),
});

const RetirementAgeConfigurationSchema = z.object({
  label: z.string(),
  value: z.string(),
});

type DatasetConfigurationSchemaType = z.infer<
  typeof DatasetConfigurationSchema
>;

type RetirementAgeConfigurationSchemaType = z.infer<
  typeof RetirementAgeConfigurationSchema
>;

const DatasetConfiguration = () => {
  const form = useForm<DatasetConfigurationSchemaType>({
    resolver: zodResolver(DatasetConfigurationSchema),
  });

  const { handleSubmit, setValue, reset, watch, formState } = form;

  const { isLoading } = formState;

  const [openSelectDatasetDialog, setOpenSelectDatasetDialog] = useState(false);

  const { project_id, org_id } = useParams();

  const projectId = Number(project_id);
  const orgId = Number(org_id);

  const { data: datasets } = useGetDatasets(
    {
      projectId,
      orgId,
    },
    {
      enabled: !!projectId && !!orgId,
    }
  );

  useEffect(() => {
    if (datasets) {
      reset({
        label: datasets[0].name,
        value: datasets[0].id.toString(),
      });
    }
  }, [datasets]);

  const handleCloseDatasetDialog = () => {
    setOpenSelectDatasetDialog(false);
  };

  const handleOpenDatasetDialog = () => {
    setOpenSelectDatasetDialog(true);
  };

  const handleOnSubmit = () => {
    const { value } = watch();

    const datasetData = datasets?.find((d) => _.isEqual(d.id, value));

    if (!datasetData) {
      return;
    }

    setValue('label', datasetData.name);
    handleCloseDatasetDialog();
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <div className="flex flex-col gap-3">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold">Dataset</h1>
            <Button
              variant="outlined"
              style={{
                color: '#B8341B',
                borderColor: '#B8341B',
              }}
              onClick={handleOpenDatasetDialog}
            >
              Edit
            </Button>
          </div>
          <div>
            <FormInput disabled name={'label'} />
          </div>
        </div>
        <Dialog
          maxWidth="sm"
          fullWidth
          open={openSelectDatasetDialog}
          onClose={handleCloseDatasetDialog}
        >
          <DialogTitle style={{ cursor: 'move' }}>
            <h2 className="font-bold">Select Dataset</h2>
          </DialogTitle>
          <DialogContent>
            <div className="w-full">
              {!!datasets && (
                <FormSelect
                  name="value"
                  fullWidth
                  options={datasets.map((dataset) => ({
                    label: dataset.name,
                    value: dataset.id,
                  }))}
                />
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex gap-3 px-4 pb-3">
              <Button
                autoFocus
                variant="outlined"
                type="button"
                onClick={handleCloseDatasetDialog}
                tabIndex={3}
                style={{
                  color: '#666666',
                  borderColor: '#CCC',
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                autoFocus
                loading={isLoading}
                onClick={handleOnSubmit}
                tabIndex={3}
                style={{
                  backgroundColor: '#B8341B',
                  color: '#FFF',
                }}
              >
                Save
              </LoadingButton>
            </div>
          </DialogActions>
        </Dialog>
      </form>
    </FormProvider>
  );
};

const RetirementAgeConfiguration = () => {
  const form = useForm<RetirementAgeConfigurationSchemaType>({
    resolver: zodResolver(RetirementAgeConfigurationSchema),
    defaultValues: {
      value: '65',
      label: '65',
    },
  });

  const { handleSubmit, setValue, watch, formState } = form;

  const { isLoading } = formState;

  const [openSelectDatasetDialog, setOpenSelectDatasetDialog] = useState(false);

  const handleCloseDatasetDialog = () => {
    setOpenSelectDatasetDialog(false);
  };

  const handleOpenDatasetDialog = () => {
    setOpenSelectDatasetDialog(true);
  };

  const handleOnSubmit = () => {
    const { value } = watch();

    setValue('label', value);
    handleCloseDatasetDialog();
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <div className="flex flex-col gap-3">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold">Retirement Age</h1>
            <Button
              variant="outlined"
              style={{
                color: '#B8341B',
                borderColor: '#B8341B',
              }}
              onClick={handleOpenDatasetDialog}
            >
              Edit
            </Button>
          </div>
          <div>
            <FormInput disabled name={'label'} />
          </div>
        </div>
        <Dialog
          maxWidth="sm"
          fullWidth
          open={openSelectDatasetDialog}
          onClose={handleCloseDatasetDialog}
        >
          <DialogTitle style={{ cursor: 'move' }}>
            <h2 className="font-bold">Edit retirement age</h2>
          </DialogTitle>
          <DialogContent>
            <div className="w-full">
              <FormInput
                fullWidth
                placeholder="Enter retirement age"
                type="number"
                name="value"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex gap-3 px-4 pb-3">
              <Button
                autoFocus
                variant="outlined"
                type="button"
                onClick={handleCloseDatasetDialog}
                tabIndex={3}
                style={{
                  color: '#666666',
                  borderColor: '#CCC',
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                autoFocus
                loading={isLoading}
                onClick={handleOnSubmit}
                tabIndex={3}
                style={{
                  backgroundColor: '#B8341B',
                  color: '#FFF',
                }}
              >
                Save
              </LoadingButton>
            </div>
          </DialogActions>
        </Dialog>
      </form>
    </FormProvider>
  );
};

const ExpenseConfiguration = () => {
  const form = useForm();

  const { handleSubmit, watch } = form;

  const [openSelectDatasetDialog, setOpenSelectDatasetDialog] = useState(false);

  const { project_id, org_id, model_id } = useParams();

  const projectId = Number(project_id);
  const orgId = Number(org_id);
  const modelId = Number(model_id);

  const { data: predefinedExpenseSets } = useGetPreDefinedExpenses(
    {
      orgId,
    },
    {
      enabled: !!org_id,
    }
  );

  const SalaryExpenses = predefinedExpenseSets
    ?.filter((e) => e.group.id === 1)
    .map((e) => ({
      label: e.name,
      value: e.id.toString(),
    }));

  const BenefitsExpenses = predefinedExpenseSets
    ?.filter((e) => e.group.id === 2)
    .map((e) => ({
      label: e.name,
      value: e.id.toString(),
    }));

  const TaxesExpenses = predefinedExpenseSets
    ?.filter((e) => e.group.id === 3)
    .map((e) => ({
      label: e.name,
      value: e.id.toString(),
    }));

  const TravelExpenses = predefinedExpenseSets
    ?.filter((e) => e.group.id === 4)
    .map((e) => ({
      label: e.name,
      value: e.id.toString(),
    }));

  const Training = predefinedExpenseSets
    ?.filter((e) => e.group.id === 5)
    .map((e) => ({
      label: e.name,
      value: e.id.toString(),
    }));

  const RetirementBenefits = predefinedExpenseSets
    ?.filter((e) => e.group.id === 6)
    .map((e) => ({
      label: e.name,
      value: e.id.toString(),
    }));

  const Operations = predefinedExpenseSets
    ?.filter((e) => e.group.id === 7)
    .map((e) => ({
      label: e.name,
      value: e.id.toString(),
    }));

  const handleCloseDatasetDialog = () => {
    setOpenSelectDatasetDialog(false);
  };

  const handleOpenDatasetDialog = () => {
    setOpenSelectDatasetDialog(true);
  };

  const allDefault = Object.values(watch('default') ?? {})
    .map((e: any) => Object.keys(e).filter((key) => e[key]))
    .flat();

  const { mutateAsync: updateModel, isPending: isUpdatingModel } =
    useUpdateModel();

  const handleOnSubmit = () => {
    if (allDefault.length === 0 || !orgId || !projectId) {
      return;
    }

    const res = updateModel({
      orgId,
      projectId,
      modelId,
      data: {
        predefined_expenses: allDefault.map(Number),
      },
    });

    toastPromise({
      promise: res,
      content: 'Expense set updated successfully',
    });
    handleCloseDatasetDialog();
  };

  return (
    <div>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="flex flex-col gap-3">
            <div className="flex items-center justify-between">
              <h1 className="text-2xl font-bold">Expense</h1>
              <Button
                variant="outlined"
                style={{
                  color: '#B8341B',
                  borderColor: '#B8341B',
                }}
                onClick={handleOpenDatasetDialog}
              >
                Edit
              </Button>
            </div>
            <div className="rounded-[4px] border border-[#E4E7EC] p-6">
              {SalaryExpenses && (
                <ExpenseCollapse title="Salary" items={SalaryExpenses} />
              )}
              {BenefitsExpenses && (
                <ExpenseCollapse title="Benefits" items={BenefitsExpenses} />
              )}
              {TaxesExpenses && (
                <ExpenseCollapse title="Taxes" items={TaxesExpenses} />
              )}
              {TravelExpenses && (
                <ExpenseCollapse
                  title="Travel Expenses"
                  items={TravelExpenses}
                />
              )}
              {Training && (
                <ExpenseCollapse title="Training" items={Training} />
              )}
              {RetirementBenefits && (
                <ExpenseCollapse
                  title="Retirement Benefits"
                  items={RetirementBenefits}
                />
              )}
              {Operations && (
                <ExpenseCollapse title="Operations" items={Operations} />
              )}
            </div>
          </div>
          <Dialog
            maxWidth="sm"
            fullWidth
            open={openSelectDatasetDialog}
            onClose={handleCloseDatasetDialog}
          >
            <DialogContent>
              <div>
                <PredefinedExpensesForm />
                <UserDefinedExpensesForm />
              </div>
            </DialogContent>
            <DialogActions>
              <div className="flex gap-3 px-4 pb-3">
                <Button
                  autoFocus
                  variant="outlined"
                  type="button"
                  onClick={handleCloseDatasetDialog}
                  tabIndex={4}
                  style={{
                    color: '#666666',
                    borderColor: '#CCC',
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  autoFocus
                  variant="contained"
                  loading={isUpdatingModel}
                  onClick={handleOnSubmit}
                  tabIndex={3}
                >
                  Save
                </LoadingButton>
              </div>
            </DialogActions>
          </Dialog>
        </form>
      </FormProvider>
    </div>
  );
};

const ModelConfigurationView = () => (
  <div className="flex flex-col gap-3 overflow-y-hidden">
    <DatasetConfiguration />
    <div className="my-3 rounded-full border border-[#CCC]" />
    <RetirementAgeConfiguration />
    <div className="my-3 rounded-full border border-[#CCC]" />
    <ExpenseConfiguration />
  </div>
);

export default ModelConfigurationView;
