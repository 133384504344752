import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { FileUp } from 'lucide-react';

import { type TypeDataset, type TypeIFS } from '../../common/dataTypes';
import {
  useDownloadDatasetInputFileById,
  useGetDatasetInputFiles,
} from '../../hooks/useDatasetsHook';
import { useImportIFS } from '../../hooks/useIFSHook';
import { useGetUser } from '../../hooks/useUserHook';
import cn from '../../utils/cn';
import GenerateLoadingDialog from '../LoadingDialog';
import SuccessDialog from '../SuccessDialog';

import AlertDialog from './AlertDialog';
import DownloadTemplatesDialog from './DownloadTemplatesDialog';

type IFSTableData = {
  inputFile: string | null;
  status: 'imported' | 'fail' | 'not-imported';
  importDate: string | null;
};

const defaultData = [] as IFSTableData[];

const columnHelper = createColumnHelper<IFSTableData>();

const columns = [
  columnHelper.accessor('inputFile', {
    id: 'inputFile',
    cell: (info) => {
      const [searchParams] = useSearchParams();

      const { org_id } = useParams();

      const { mutate: downloadInputFile } = useDownloadDatasetInputFileById();

      const status = info.row.original.status;
      const name = info.row.original.inputFile;

      const orgId = Number(org_id);
      const datasetId = Number(searchParams.get('dataset_id'));
      const projectId = Number(searchParams.get('project_id'));

      const { data: inputFiles } = useGetDatasetInputFiles(
        {
          orgId,
          projectId,
          datasetId,
        },
        {
          enabled: !!orgId && !!projectId && !!datasetId,
        }
      );

      const handleDownload = () => {
        const file = inputFiles?.find((i) => i.name === name);

        if (
          !orgId ||
          !projectId ||
          !name ||
          !datasetId ||
          !inputFiles ||
          !file
        ) {
          return;
        }

        downloadInputFile({
          orgId,
          projectId,
          datasetId,
          fileId: file.id,
        });
      };

      return (
        <div className="px-6 text-start flex flex-wrap break-all">
          {status === 'imported' && (
            <button
              type="button"
              onClick={handleDownload}
              className="text-[#2196F3] underline cursor-pointer"
            >
              {info.getValue()}
            </button>
          )}
          {status === 'not-imported' && <p>{info.getValue()}</p>}
        </div>
      );
    },
    header: () => <div className="flex text-nowrap text-start">Input File</div>,
  }),
  columnHelper.accessor('status', {
    id: 'status',
    cell: (info) => {
      const status = info.getValue();

      const StatusLabel = ({
        label,
        value,
      }: {
        value: 'imported' | 'fail' | 'not-imported';
        label: string;
      }) => (
        <div className="flex justify-start px-6 py-2">
          <p
            className={cn(
              'w-fit rounded-full capitalize bg-[#E1FBE7] px-6 py-1 text-green-600',
              {
                'bg-[#E1FBE7] text-green-600': value === 'imported',
                'text-red-600 bg-[#FFF5F2]': value === 'fail',
                'text-[#666] bg-gray-100': value === 'not-imported',
              }
            )}
          >
            {label}
          </p>
        </div>
      );

      switch (status) {
        case 'imported':
          return <StatusLabel value="imported" label="Imported" />;
        case 'fail':
          return <StatusLabel value="fail" label="Fail" />;
        case 'not-imported':
          return <StatusLabel value="not-imported" label="Not Imported" />;
      }
    },
    header: () => <div className="text-start">Status</div>,
  }),
  columnHelper.accessor('importDate', {
    id: 'importDate',
    cell: (info) => (
      <p className="px-6 py-1 text-[#333]">
        {info.getValue()
          ? format(info.getValue() as string, 'Y/MM/dd hh:mm')
          : '--'}
      </p>
    ),
    header: () => (
      <div className="flex w-full shrink text-start">Import Date</div>
    ),
  }),
];

const ErrorContent = (data: string[]) => (
  <div className="flex flex-col gap-2">
    <p>Invalid files:</p>
    <ul className="list-disc px-8">
      {data?.map((error: string, index: number) => (
        <li key={index}>{error}</li>
      ))}
    </ul>
  </div>
);

const IFSTable = ({
  projectId,
  dataset,
  ifs,
}: {
  projectId: number;
  dataset: TypeDataset;
  ifs: TypeIFS;
}) => {
  const [data, setData] = useState<IFSTableData[]>(defaultData);

  const { data: user } = useGetUser();

  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const [error, setError] = useState<{
    content: React.ReactNode;
  } | null>(null);

  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  const { mutateAsync: importIFS, isPending: isImportingIFS } = useImportIFS({
    onSuccess() {
      setOpenSuccessDialog(true);
    },
    onError(error) {
      if (error instanceof AxiosError && error.response) {
        const data = error.response.data;

        if (data.ifs) {
          setError({
            content: ErrorContent(data.ifs),
          });
        } else {
          setError({
            content: ErrorContent(data),
          });
        }

        setOpenErrorDialog(true);
      }
    },
  });

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    if (ifs.files) {
      setData(
        ifs.files.map((file) => ({
          inputFile: file.name,
          status: file.is_imported ? 'imported' : 'not-imported',
          importDate: file.imported_at,
          import: null,
        }))
      );
    }
  }, [ifs]);

  const handleImportFiles = async (e: {
    target: { value: any; files: any };
  }) => {
    if (!user || !projectId) {
      return;
    }

    const ifsNames = ifs.files.map((file) => file.name);

    const importIfsNames = Array.from(e.target.files as File[]).map(
      (file: File) => file.name
    );

    const invalidFiles = importIfsNames.filter(
      (name) => !ifsNames.includes(name)
    );

    if (invalidFiles.length) {
      setOpenErrorDialog(true);
      setError({
        content: ErrorContent(invalidFiles),
      });

      return;
    }

    const formData = new FormData();

    Array.from(e.target.files as File[]).forEach((file: File) => {
      formData.append('ifs', file);
    });

    importIFS({
      datasetId: dataset.id,
      projectId: Number(projectId),
      orgId: user.organizations[0].id,
      data: formData,
    });

    e.target.value = '';

    if (inputFileRef?.current?.value) {
      inputFileRef.current = null;
    }
  };

  return (
    <div className="space-y-3">
      <div className="flex justify-between">
        <div className="flex items-center">
          <p className="text-xl font-bold">Upload files</p>
        </div>
        <div className="flex gap-3">
          <DownloadTemplatesDialog dataset={dataset} ifs={ifs} />
          <LoadingButton
            variant="contained"
            style={{
              display: 'flex',
              color: 'white',
              position: 'relative',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            <input
              ref={inputFileRef}
              type="file"
              accept=".xlsx"
              id="import_ifs"
              onChange={handleImportFiles}
              multiple
              className="absolute w-[140px] cursor-pointer opacity-0"
            />
            <FileUp size={20} />
            Upload File(s)
          </LoadingButton>
        </div>
      </div>
      <div>
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className="border border-[#E4E7EC] bg-[#F8F8F8]"
              >
                {headerGroup.headers.map((header, index) => (
                  <th
                    key={header.id}
                    className={cn(
                      'border border-[#E4E7EC] px-6 py-2 text-sm text-[#4D4D4D]',
                      {
                        'w-[50%]': index === 0,
                      }
                    )}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="border border-[#E4E7EC]">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {!table.getRowModel().rows.length && (
          <div className="flex justify-center border border-t-0 border-[#E4E7EC] py-20">
            <p className="text-lg text-[#999]">
              No IFS data available. Please generate and upload your files.
            </p>
          </div>
        )}
      </div>
      <div className="h-4" />
      <AlertDialog
        title="Error Found: Please Review"
        open={openErrorDialog}
        content={error?.content}
        handleOnClose={() => {
          setOpenErrorDialog(false);
        }}
      />
      <SuccessDialog
        content="Input File Set imported successfully."
        open={openSuccessDialog}
        handleOnClose={() => {
          setOpenSuccessDialog(false);
        }}
      />
      <GenerateLoadingDialog title="Uploading" open={isImportingIFS} />
    </div>
  );
};

export default IFSTable;
