import React, { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { Button } from '@mui/material';
import { format } from 'date-fns';

import { type TypeProject } from '../../common/dataTypes';
import { useGetDatasetById, useGetProjectById } from '../../hooks';
import { useGetIFSConfig } from '../../hooks/useDatasetsHook';
import { useCompleteOnboarding } from '../../hooks/useProjectsHook';
import {
  generateMonthList,
  generateWeekList,
  transformToLabel,
} from '../../utils/helpers';
import ReviewIFSFilesTabs from '../ReviewIFSFilesTabs';

import { handleGoBackPreviousStep } from './ProjectCreateView';

type ProjectReviewConfirmProps = {
  handleOnSubmit?: (data: TypeProject) => void;
};

const ProjectReviewConfirm = ({
  handleOnSubmit,
}: ProjectReviewConfirmProps) => {
  const [searchParams] = useSearchParams();

  const monthList = useMemo(() => generateMonthList(), []);
  const weekList = useMemo(() => generateWeekList(), []);

  const { org_id } = useParams();

  const step = searchParams.get('step');

  const orgId = Number(org_id);
  const datasetId = Number(searchParams.get('dataset_id'));
  const projectId = Number(searchParams.get('project_id'));

  const { data: dataset } = useGetDatasetById(
    {
      datasetId,
      orgId,
      projectId,
    },
    {
      enabled: !!datasetId && !!orgId && !!projectId,
    }
  );

  const { data: ifsConfig } = useGetIFSConfig(
    {
      orgId,
      projectId,
    },
    {
      enabled: !!orgId && !!projectId,
    }
  );

  const { data: project } = useGetProjectById(
    {
      projectId,
      orgId,
    },
    {
      enabled: !!projectId && !!orgId,
    }
  );

  const { mutateAsync: completeOnboarding } = useCompleteOnboarding();

  const aircrafts = useMemo(() => ifsConfig?.aircrafts, [ifsConfig?.aircrafts]);

  const startMonthOrWeek = ifsConfig?.start_period.split('-')[0];
  const endMonthOrWeek = ifsConfig?.end_period.split('-')[0];

  const startMonthOrWeekLabel =
    ifsConfig?.granularity === 'monthly'
      ? monthList.find((m) => m.value === startMonthOrWeek)?.label
      : weekList.find((w) => w.value === startMonthOrWeek)?.label;

  const endMonthOrWeekLabel =
    ifsConfig?.granularity === 'monthly'
      ? monthList.find((m) => m.value === endMonthOrWeek)?.label
      : weekList.find((w) => w.value === endMonthOrWeek)?.label;

  const onSubmit = async () => {
    if (!projectId || !orgId) {
      return;
    }

    const res = await completeOnboarding({
      projectId,
      orgId,
    });

    handleOnSubmit?.(res);
  };

  return (
    <div className="bg-white space-y-6">
      <div className="flex flex-col gap-3">
        <h1 className="text-xl font-bold">Project</h1>
        <div className="flex flex-col gap-3 rounded-[4px] border border-[#E4E7EC] bg-[#FFF] px-6 py-4">
          <div className="flex">
            <p className="w-[20%]">Project Name:</p>
            <p className="w-full font-bold">{project?.name}</p>
          </div>
          <div className="flex">
            <p className="w-[20%]">Description:</p>
            <p className="w-full font-bold">{project?.description}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <h2 className="text-nowrap text-xl font-bold">IFS Configuration</h2>
        <div className="border border-[#E4E7EC] rounded-[4px] p-6 space-y-8">
          <div className="flex flex-col gap-3 bg-[#FFF]">
            <div className="flex">
              <p className="w-[20%]">Last modified:</p>
              <p className="w-full font-bold">
                {ifsConfig?.updated_at
                  ? format(ifsConfig?.updated_at as string, 'Y/MM/dd')
                  : ''}
              </p>
            </div>
            <div className="flex">
              <p className="w-[20%]">Created by:</p>
              <p className="w-full font-bold">{ifsConfig?.created_by}</p>
            </div>
          </div>
          <div className="bg-white flex flex-col gap-3">
            <h2 className="text-xl font-bold">Reporting Period</h2>
            <div className="flex gap-6">
              <p className="capitalize">
                Granularity:{' '}
                <span className="font-bold">{ifsConfig?.granularity}</span>
              </p>
              <p>
                Duration:{' '}
                <span className="font-bold">{ifsConfig?.duration}</span>
              </p>
              <p>
                Start:{' '}
                <span className="font-bold">
                  {(startMonthOrWeekLabel ? `${startMonthOrWeekLabel}-` : '') +
                    ifsConfig?.start_period.split('-')[
                      ifsConfig?.start_period.split('-').length - 1
                    ]}
                </span>
              </p>
              <p>
                End:{' '}
                <span className="font-bold">
                  {(endMonthOrWeekLabel ? `${endMonthOrWeekLabel}-` : '') +
                    ifsConfig?.end_period.split('-')[
                      ifsConfig?.end_period.split('-').length - 1
                    ]}
                </span>
              </p>
            </div>
          </div>
          <div className="bg-white flex flex-col gap-3">
            <h2 className="text-xl font-bold">Aircraft and Airports</h2>
            <table className="border-separate border-spacing-0 rounded-[4px] border  border-[#E4E7EC]">
              {aircrafts?.map((a: { name: string; airports: string[] }) => (
                <tr key={a.name}>
                  <td className="w-[5%] border-b border-r px-6 py-2 last:border-b-0">
                    {a.name}
                  </td>
                  <td className="border-b px-6 py-2">
                    {(a.airports as string[])?.join(', ')}
                  </td>
                </tr>
              ))}
            </table>
          </div>
          <div className="bg-white flex flex-col gap-3">
            <h2 className="text-xl font-bold">Labor Group and Positions</h2>
            <table className="border-separate border-spacing-0 rounded-[4px] border  border-[#E4E7EC]">
              <tr>
                <td className="w-[5%] border-r px-6 py-2 capitalize">
                  {ifsConfig?.labor_group}
                </td>
                <td className="px-6 py-2">
                  {ifsConfig?.positions
                    .map((p: { name: string }) => transformToLabel(p.name))
                    .join(', ')}
                </td>
              </tr>
            </table>
            <p>
              Retirement Age:{' '}
              <span className="font-bold">{ifsConfig?.retirement_age}</span>
            </p>
          </div>
          <div className="bg-white flex flex-col gap-3">
            <h2 className="text-xl font-bold">Years of Service</h2>
            <p>
              Years of Service:{' '}
              <span className="font-bold">{ifsConfig?.years_of_service}</span>
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <h1 className="text-xl font-bold">Dataset</h1>
        <div className="flex flex-col gap-3 rounded-[4px] border border-[#E4E7EC] bg-[#FFF] px-6 py-4">
          <div className="flex">
            <p className="w-[20%]">Dataset Name:</p>
            <p className="w-full font-bold">{dataset?.name}</p>
          </div>
          <div className="flex">
            <p className="w-[20%]">Description:</p>
            <p className="w-full font-bold">{dataset?.description}</p>
          </div>
        </div>
      </div>
      <div>{dataset && <ReviewIFSFilesTabs dataset={dataset} />}</div>
      <div className="flex justify-end">
        <div className="flex gap-2">
          <Button
            variant="outlined"
            onClick={() => {
              if (step) {
                handleGoBackPreviousStep(step);
              }
            }}
            style={{
              color: '#666',
              borderColor: '#CCC',
              fontWeight: 600,
            }}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            style={{
              fontWeight: 600,
            }}
            onClick={onSubmit}
          >
            Finalize project
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProjectReviewConfirm;
