import { mainApi } from '../utils/api';

export type CreateModelInput = {
  orgId: number;
  projectId: number;
  data: {
    name: string;
    description?: string;
    dataset: number;
    model?: number;
  };
};

export type UpdateModelInput = {
  orgId: number;
  projectId: number;
  modelId: number;
  data: {
    name?: string;
    description?: string;
    predefined_expenses?: number[];
    userdefined_expenses?: number[];
    model?: number;
  };
};

export type GetModelsInput = {
  orgId: number;
  projectId: number;
};

export type ShareModelInput = {
  modelId: number;
  orgId: number;
  projectId: number;
  data: {
    guests: string;
  };
};

export type GetModelsByDatasetIdInput = {
  orgId: number;
  projectId: number;
  datasetId: number;
};

export type GetModelByIdInput = {
  modelId: number;
  orgId: number;
  projectId: number;
};

export type GetModelOutputInput = {
  orgId: number;
  projectId: number;
  modelId: number;
};

export type RunModelOutputInput = {
  orgId: number;
  projectId: number;
  modelId: number;
  data: {
    filters?: {
      aircraft_type?: string;
      granularity?: string;
    };
  };
};

export type DeleteModelInput = {
  orgId: number;
  projectId: number;
  modelId: number;
};

export type CloneModelInput = {
  orgId: number;
  projectId: number;
  modelId: number;
};

export type ShareModelToHostInput = {
  orgId: number;
  projectId: number;
  modelId: number;
};

const getModels = ({ orgId, projectId }: GetModelsInput) =>
  mainApi.get(`organizations/${orgId}/projects/${projectId}/models`);

const shareModel = ({ orgId, data, projectId, modelId }: ShareModelInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/share/`,
    data
  );

const shareModelToHost = ({
  orgId,
  projectId,
  modelId,
}: ShareModelToHostInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/share_to_host/`
  );

const cloneModel = async ({ orgId, projectId, modelId }: CloneModelInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/clone/`
  );

const getModelsByDatasetId = ({
  orgId,
  projectId,
  datasetId,
}: GetModelsByDatasetIdInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/models/?dataset=${datasetId}`
  );

const getModelById = ({ orgId, projectId, modelId }: GetModelByIdInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/`
  );

const getModelOutput = ({ modelId, orgId, projectId }: GetModelOutputInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/output/`
  );

const runModelOutput = ({
  modelId,
  orgId,
  projectId,
  data,
}: RunModelOutputInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/run/`,
    data
  );

const createModel = ({ orgId, projectId, data }: CreateModelInput) =>
  mainApi.post(`organizations/${orgId}/projects/${projectId}/models/`, data);

const deleteModelById = ({ orgId, projectId, modelId }: DeleteModelInput) =>
  mainApi.delete(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/`
  );

const updateModel = ({ orgId, projectId, modelId, data }: UpdateModelInput) =>
  mainApi.put(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/`,
    data
  );

export {
  getModels,
  cloneModel,
  createModel,
  deleteModelById,
  getModelById,
  getModelOutput,
  getModelsByDatasetId,
  shareModel,
  shareModelToHost,
  updateModel,
  runModelOutput,
};
