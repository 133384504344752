import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useCurrentProject } from '../../utils/helpers';
import CreateDatasetDialog from '../dataset/CreateDatasetDialog';
import DatasetsTable from '../dataset/DatasetsTable';
import LastModifiedFilter from '../LastModifiedFilter';
import SearchInput from '../SearchInput';

const ProjectDatasetsManagement = () => {
  const [openCreateDatasetDialog, setOpenCreateDatasetDialog] = useState(false);

  const project = useCurrentProject();

  const form = useForm();

  const handleCloseCreateDatasetDialog = () => {
    setOpenCreateDatasetDialog(false);
  };

  return (
    <div className="flex w-full flex-col gap-6 px-12 pb-6">
      <h1 className="text-2xl font-bold">Dataset</h1>
      <div className="flex items-center justify-between">
        <FormProvider {...form}>
          <form className="flex items-center gap-3">
            <div className="w-[300px]">
              <SearchInput />
            </div>
            <LastModifiedFilter />
          </form>
        </FormProvider>
        {project?.role === 'project_owner' && (
          <CreateDatasetDialog
            open={openCreateDatasetDialog}
            handleClose={handleCloseCreateDatasetDialog}
          />
        )}
      </div>
      <DatasetsTable />
    </div>
  );
};

export default ProjectDatasetsManagement;
