import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  Autocomplete,
  Box,
  FormControl,
  type InputBaseComponentProps,
  TextField,
} from '@mui/material';
import _ from 'lodash';

import { transformToLabel } from '../utils/helpers';

type Option = {
  label: string;
  value: string;
};

type FormAutoCompleteProps = {
  label?: string;
  name: string;
  options: Option[];
  defaultValue?: string | number;
  isError?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  type?: 'text' | 'number';
  disabled?: boolean;
  style?: React.CSSProperties;
  inputProps?: InputBaseComponentProps;
};

const FormAutoComplete = ({
  label,
  defaultValue,
  name,
  options,
  style,
  ...props
}: FormAutoCompleteProps) => {
  const [value, setValue] = React.useState<string | null>(
    defaultValue?.toString() ?? options[0].label
  );

  const [inputValue, setInputValue] = React.useState(
    defaultValue?.toString() ?? options[0].label
  );

  const { formState, setValue: setFormValue } = useFormContext();

  const { errors } = formState;

  const errorMessage = errors[name]?.message as string | undefined;

  useEffect(() => {
    setFormValue(
      name,
      options.find((o) => o.label === inputValue)?.value ?? inputValue,
      {
        shouldDirty: !_.isEqual(inputValue, value),
      }
    );
  }, [value, inputValue, _.isEqual(inputValue, value)]);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue.toString());
      setInputValue(defaultValue.toString());
    }
  }, [defaultValue]);

  return (
    <FormControl
      {...props}
      style={{
        ...style,
      }}
    >
      <label htmlFor={name} className="min-h-7 pb-2 text-sm text-[#666]">
        {label ? transformToLabel(label) : ' '}
      </label>
      <Autocomplete
        id={name}
        options={options.map((option) => option.label)}
        size="small"
        value={value}
        freeSolo
        onChange={(event: any, newValue: string | null) => setValue(newValue)}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        autoHighlight
        getOptionLabel={(option) => option}
        renderOption={(props, option) => {
          const { ...optionProps } = props;

          return (
            <Box component="li" {...optionProps} key={option}>
              {option}
            </Box>
          );
        }}
        renderInput={(params) => (
          <TextField
            defaultValue={defaultValue}
            {...params}
            fullWidth
            size="small"
            name={name}
            type={props.type}
            inputProps={{
              ...params.inputProps,
              ...props.inputProps,
            }}
          />
        )}
      />
      <div className="flex justify-between">
        {errorMessage && (
          <p className="w-full pt-2 text-start text-[14px] text-[#FF816A]">
            {errorMessage}
          </p>
        )}
      </div>
    </FormControl>
  );
};

export default FormAutoComplete;
