import {
  useMutation,
  type UseMutationOptions,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';

import {
  createUserDefinedExpense,
  type CreateUserDefinedExpenseInput,
  deleteUserDefinedExpense,
  type DeleteUserDefinedExpenseInput,
  getPreDefinedExpenseById,
  type GetPreDefinedExpenseByIdInput,
  getPreDefinedExpenses,
  type GetPreDefinedExpensesInput,
  getUserDefinedExpenseById,
  type GetUserDefinedExpenseByIdInput,
  getUserDefinedExpenses,
  type GetUserDefinedExpensesInput,
  updateUserDefinedExpense,
  type UpdateUserDefinedExpenseInput,
} from '../api/expenses';
import {
  type TypeExpenseSet,
  type TypePredefinedExpense,
  type TypeUserDefinedExpense,
} from '../common/dataTypes';

import { type DefaultMutationError, type DefaultQueryError } from './index';

export const useGetPreDefinedExpenses = (
  input: GetPreDefinedExpensesInput,
  opts?: Partial<
    UseQueryOptions<Awaited<TypePredefinedExpense[]>, DefaultQueryError>
  >
) =>
  useQuery({
    queryKey: ['getPreDefinedExpenses', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypePredefinedExpense[]> =
        await getPreDefinedExpenses(input);

      return data;
    },
    ...opts,
  });

export const useGetPreDefinedExpenseById = (
  input: GetPreDefinedExpenseByIdInput,
  opts?: Partial<
    UseQueryOptions<Awaited<TypePredefinedExpense[]>, DefaultQueryError>
  >
) =>
  useQuery({
    queryKey: ['getPreDefinedExpenseSetById', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypePredefinedExpense[]> =
        await getPreDefinedExpenseById(input);

      return data;
    },
    ...opts,
  });

export const useGetUserDefinedExpenses = (
  input: GetUserDefinedExpensesInput,
  opts?: Partial<
    UseQueryOptions<Awaited<TypeUserDefinedExpense[]>, DefaultQueryError>
  >
) =>
  useQuery({
    queryKey: ['getUserDefinedExpenses', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeUserDefinedExpense[]> =
        await getUserDefinedExpenses(input);

      return data;
    },
    ...opts,
  });

export const useGetUserDefinedExpenseById = (
  input: GetUserDefinedExpenseByIdInput,
  opts?: Partial<
    UseQueryOptions<Awaited<TypeUserDefinedExpense[]>, DefaultQueryError>
  >
) =>
  useQuery({
    queryKey: ['getUserDefinedExpenseById', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeUserDefinedExpense[]> =
        await getUserDefinedExpenseById(input);

      return data;
    },
    ...opts,
  });

export const useUpdateUserDefinedExpense = (
  opts?: Partial<
    UseMutationOptions<Awaited<any>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['updateUserDefinedExpense'],
    mutationFn: async (input: UpdateUserDefinedExpenseInput) => {
      const { data }: AxiosResponse<TypeExpenseSet> =
        await updateUserDefinedExpense(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getUserDefinedExpenseById'],
      });
      queryClient.refetchQueries({
        queryKey: ['getUserDefinedExpenses'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useCreateUserDefinedExpense = (
  opts?: Partial<
    UseMutationOptions<Awaited<any>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['createUserDefinedExpense'],
    mutationFn: async (input: CreateUserDefinedExpenseInput) => {
      const { data }: AxiosResponse<TypeExpenseSet> =
        await createUserDefinedExpense(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getUserDefinedExpenses'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useDeleteUserDefinedExpense = (
  opts?: Partial<
    UseMutationOptions<Awaited<any>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['deleteUserDefinedExpense'],
    mutationFn: async (input: DeleteUserDefinedExpenseInput) => {
      const { data }: AxiosResponse<TypeExpenseSet> =
        await deleteUserDefinedExpense(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getUserDefinedExpenses'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};
