import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Dialog } from '@mui/material';
import { Trash2 } from 'lucide-react';

import { toastPromise } from '../../common/utils';
import {
  useDeleteInvitationById,
  useDeleteMemberById,
} from '../../hooks/useOrganizationHook';

type UserType = {
  avatarImage?: string;
  email: string;
  id: number;
  name?: string | undefined | null;
  type?: 'member' | 'invitation';
};

type ConfirmDeleteUserDialogProps = {
  user: UserType;
  trigger?: {
    label?: React.ReactNode;
    className?: React.HTMLAttributes<HTMLButtonElement>['className'];
  };
};

const ConfirmDeleteUserDialog = ({
  user,
  trigger,
}: ConfirmDeleteUserDialogProps) => {
  const [open, setOpen] = useState(false);

  const { org_id } = useParams();

  const orgId = Number(org_id);

  const { mutateAsync: deleteMemberById } = useDeleteMemberById({
    onSuccess: () => {
      setOpen(false);
    },
  });

  const { mutateAsync: deleteInvitationById } = useDeleteInvitationById({
    onSuccess: () => {
      setOpen(false);
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async () => {
    if (!orgId || !user) {
      return;
    }

    let res;

    if (user.type === 'member') {
      res = deleteMemberById({
        id: orgId,
        userId: user.id,
      });
    } else if (user.type === 'invitation') {
      res = deleteInvitationById({
        id: orgId,
        invitationId: user.id,
      });
    }

    if (res) {
      toastPromise({
        promise: res,
        content: 'User has been deleted successfully',
      });
    }
  };

  return (
    <>
      <button type="button" onClick={handleOpen} className={trigger?.className}>
        <Trash2 width={20} height={20} />
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alertTitle"
        aria-describedby="alertContent"
      >
        <div className="flex flex-col gap-6 p-6">
          <h2 className="text-xl font-semibold">
            Are you sure you want to delete{' '}
            <span className="font-bold text-xl">{user.email}</span> from the
            organization?
          </h2>
          <div className="flex justify-end gap-3">
            <Button
              onClick={handleClose}
              variant="outlined"
              tabIndex={3}
              style={{
                color: '#666',
                borderColor: '#B3B3B3',
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              tabIndex={2}
              variant="contained"
              style={{
                backgroundColor: '#B8341B',
                color: '#FFF',
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ConfirmDeleteUserDialog;
