import { mainApi } from '../utils/api';

export type ImportIFSInput = {
  datasetId: number;
  orgId: number;
  data: FormData;
  projectId: number;
};

export type GetIFSInputInput = {
  datasetId: number;
  projectId: number;
  orgId: number;
};

export type GenerateIFSInput = {
  datasetId: number;
  projectId: number;
  orgId: number;
};

const getIFS = ({ datasetId, orgId, projectId }: GetIFSInputInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/datasets/${datasetId}/ifs_schema/`
  );

const importIFS = ({ datasetId, orgId, data, projectId }: ImportIFSInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/datasets/${datasetId}/import_ifs/`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

export { getIFS, importIFS };
