import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Alert, Button } from '@mui/material';
import * as z from 'zod';

import {
  useCreateDataset,
  useUpdateDataset,
} from '../../hooks/useDatasetsHook';
import { handleQueryError } from '../../utils/api';
import FormInput from '../FormInput';

const CreateDatasetSchema = z.object({
  name: z.string().trim().trim().min(1, 'Dataset name is required'),
  description: z.string().trim().optional(),
});

type CreateProjectSchemaType = z.infer<typeof CreateDatasetSchema>;

type CreateDatasetFormProps = {
  handleOnCancel?: () => void;
  handleOnSubmit?: () => void;
  defaultValues?: CreateProjectSchemaType & {
    id: number;
  };
};

const CreateDatasetForm = ({
  defaultValues,
  handleOnCancel,
  handleOnSubmit,
}: CreateDatasetFormProps) => {
  const [error, setError] = useState(Object);

  const form = useForm<CreateProjectSchemaType>({
    resolver: zodResolver(CreateDatasetSchema),
    defaultValues,
  });

  const { handleSubmit, reset } = form;

  const { mutate: createDataset, isPending: isCreatingProject } =
    useCreateDataset({
      onError: (error) => {
        handleQueryError(error, setError);
      },
    });

  const { mutate: updateDataset, isPending: isUpdatingProject } =
    useUpdateDataset({
      onError: (error) => {
        handleQueryError(error, setError);
      },
    });

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues]);

  const handleCreateDataset = () => {
    //...
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(handleCreateDataset)} className="space-y-4 ">
        <div className="space-y-4">
          <FormInput
            label="Name"
            fullWidth
            inputProps={{
              maxLength: 100,
            }}
            placeholder="Name of dataset"
            name="name"
          />
          <FormInput
            label="Description"
            fullWidth
            multiline
            rows={4}
            placeholder="(Optional)"
            name="description"
          />
          <div className="flex justify-end gap-3 pb-4">
            <Button
              onClick={handleOnCancel}
              variant="outlined"
              style={{
                color: '#666',
                borderColor: '#B3B3B3',
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={isCreatingProject ?? isUpdatingProject}
              type="submit"
              variant="contained"
            >
              {defaultValues ? 'Save' : 'Create Dataset'}
            </LoadingButton>
          </div>
        </div>
        {typeof error.detail === 'string' && (
          <Alert severity="error">{error.detail}</Alert>
        )}
      </form>
    </FormProvider>
  );
};

export default CreateDatasetForm;
