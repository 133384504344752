import React, { useEffect, useState } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import * as z from 'zod';

import { useCreateModel, useGetDatasets, useUpdateModel } from '../../hooks';
import { useGetModels } from '../../hooks/useModelsHook';
import { handleQueryError } from '../../utils/api';
import FormInput from '../FormInput';
import FormSelect from '../FormSelect';

const CreateModelSchema = z.object({
  name: z.string().trim(),
  description: z.string().trim().optional(),
  dataset: z.string().optional(),
  model: z.number().or(z.string()).optional(),
  isCreateNew: z.boolean().optional(),
});

type CreateModelSchemaType = z.infer<typeof CreateModelSchema>;

type CreateModelFormDialogProps = {
  open: boolean;
  title: string;
  defaultValues?: {
    name: string;
    description: string;
  };
  handleOpenDialog: () => void;
  handleCloseDialog: () => void;
};

const CreateModelFormDialog = ({
  open,
  title,
  handleOpenDialog,
  handleCloseDialog,
  defaultValues,
}: CreateModelFormDialogProps) => {
  const [error, setError] = useState();

  const { project_id, org_id, model_id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const modelId = Number(model_id);
  const orgId = Number(org_id);
  const projectId = Number(project_id);

  const { data: datasets } = useGetDatasets(
    {
      projectId,
      orgId,
    },
    {
      enabled: !!orgId && !!projectId,
    }
  );

  const { data: models } = useGetModels(
    {
      projectId,
      orgId,
    },
    {
      enabled: !!projectId && !!orgId,
    }
  );

  const form = useForm<CreateModelSchemaType>({
    resolver: zodResolver(CreateModelSchema),
    defaultValues: defaultValues
      ? {
          name: defaultValues.name,
          description: defaultValues.description,
          dataset: datasets?.[0]?.id.toString(),
        }
      : {
          name: '',
          description: '',
          dataset: '',
        },
  });

  const { handleSubmit, formState, watch, reset, register } = form;
  const { isSubmitting, errors } = formState;

  const isCreateNewDataset = watch('isCreateNew');

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const { mutate: createModel } = useCreateModel({
    onSuccess(data) {
      searchParams.set('model_id', data.id?.toString());

      setSearchParams(searchParams);

      reset();
      handleCloseDialog();
    },
    onError(error) {
      handleQueryError(error, setError);
    },
  });

  const { mutate: updateModel } = useUpdateModel({
    onSuccess() {
      reset();
      handleCloseDialog();
    },
    onError(error) {
      handleQueryError(error, setError);
    },
  });

  const onSubmit: SubmitHandler<CreateModelSchemaType> = async () => {
    const watchData = watch();

    const { name, description, model, dataset } = watchData;

    const data = {
      name,
      description,
      dataset: Number(dataset),
      model: Number(model),
      project: projectId,
    };

    if (defaultValues) {
      updateModel({
        orgId,
        projectId,
        modelId,
        data,
      });
      return;
    }

    createModel({
      orgId: Number(org_id),
      projectId: Number(project_id),
      data,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleCloseDialog();
        reset();
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className="w-full">
        <h2 className="pt-2 text-2xl font-bold">{title}</h2>
      </DialogTitle>
      <DialogContent>
        <div>
          <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)} className="mb-2 space-y-4">
              <div className="space-y-4">
                <>
                  <FormInput
                    label="Model Name"
                    name="name"
                    inputProps={{
                      maxLength: 30,
                    }}
                    required
                    fullWidth
                  />
                  <FormInput
                    label="Model Description"
                    name="description"
                    fullWidth
                    multiline
                    rows={5}
                  />
                </>
                {models && !defaultValues && (
                  <FormSelect
                    label="Copy scenario from another model"
                    name="model"
                    defaultValue="none"
                    options={[
                      {
                        value: 'none',
                        label: "Don't copy",
                      },
                      ...models.map((model) => ({
                        value: model.id,
                        label: model.name,
                      })),
                    ]}
                    fullWidth
                  />
                )}
                {datasets && !defaultValues && (
                  <FormSelect
                    label="Select Dataset"
                    name="dataset"
                    defaultValue={datasets[0]?.id}
                    options={datasets.map((dataset) => ({
                      value: dataset.id,
                      label: dataset.name,
                    }))}
                    fullWidth
                  />
                )}
                {!defaultValues && (
                  <div className="flex items-center gap-1">
                    <Checkbox {...register('isCreateNew')} />
                    <p>Create new dataset base on this dataset.</p>
                  </div>
                )}
                {isCreateNewDataset && (
                  <>
                    <FormInput
                      label="Dataset Name"
                      name="newDatasetName"
                      inputProps={{
                        maxLength: 30,
                      }}
                      required
                      fullWidth
                    />
                    <FormInput
                      label="Dataset Description"
                      name="newDatasetDescription"
                      fullWidth
                      multiline
                      rows={5}
                    />
                  </>
                )}
              </div>
              <div className="flex-end flex justify-end gap-3">
                <Button
                  onClick={handleCloseDialog}
                  variant="outlined"
                  style={{
                    color: '#666',
                    borderColor: '#B3B3B3',
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  loading={isSubmitting}
                  variant="contained"
                  style={{
                    backgroundColor: '#2196F3',
                    textTransform: 'capitalize',
                    color: '#FFF',
                  }}
                >
                  {defaultValues ? 'Save' : 'Create'}
                </LoadingButton>
              </div>
            </form>
          </FormProvider>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CreateModelFormDialog;
