import {
  useMutation,
  type UseMutationOptions,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';

import {
  createProject,
  deleteProject,
  getProjectById,
  getProjectMembers,
  getProjects,
  getProjectSharedModels,
} from '../api';
import {
  type TypeModel,
  type TypeProject,
  type TypeProjectMember,
  type TypeProjectOutputSchema,
} from '../common/dataTypes';

import {
  type CompleteCreateProjectInput,
  completeOnboarding,
  type CreateProjectInput,
  type DeleteProjectInput,
  deleteProjectMember,
  type DeleteProjectMemberInput,
  type GetProjectByIdInput,
  type GetProjectMembersInput,
  getProjectOutputSchema,
  type GetProjectOutputSchemaInput,
  type GetProjectSharedModelsInput,
  type GetProjectsInput,
  updateProject,
  type UpdateProjectInput,
  updateProjectMember,
  type UpdateProjectMemberInput,
} from './../api/project';
import { type DefaultMutationError, type DefaultQueryError } from './index';

const createProjectFn = async (data: CreateProjectInput) => {
  const {
    data: project,
  }: AxiosResponse<{
    id: number;
  }> = await createProject(data);

  return project;
};

export const useGetProjects = (
  input: GetProjectsInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeProject[]>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getProjects', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeProject[]> = await getProjects(input);

      const result = data.sort((p1: TypeProject, p2: TypeProject) => {
        if (new Date(p1.created_at) > new Date(p2.created_at)) {
          return -1;
        } else {
          return 1;
        }
      });

      return result;
    },
    ...opts,
  });

export const useCreateProject = (
  opts?: Partial<
    UseMutationOptions<Awaited<TypeProject>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['createProject'],
    mutationFn: async (input: CreateProjectInput) => {
      const { data: project }: AxiosResponse<TypeProject> =
        await createProject(input);

      return project;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getProjects', variables.orgId],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useGetProjectById = (
  input: GetProjectByIdInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeProject>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getProjectById', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeProject> = await getProjectById(input);

      return data;
    },
    ...opts,
  });

export const useGetProjectOutputSchema = (
  input: GetProjectOutputSchemaInput,
  opts?: Partial<
    UseQueryOptions<Awaited<TypeProjectOutputSchema>, DefaultQueryError>
  >
) =>
  useQuery({
    queryKey: ['getProjectOutputSchema', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeProjectOutputSchema> =
        await getProjectOutputSchema(input);

      return data;
    },
    ...opts,
  });

export const useDeleteProject = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['deleteProject'],
    mutationFn: async (input: DeleteProjectInput) => {
      const { data }: AxiosResponse = await deleteProject(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getProjects'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useUpdateProject = (
  opts?: Partial<
    UseMutationOptions<Awaited<TypeProject>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['updateProject'],
    mutationFn: async (input: UpdateProjectInput) => {
      const { data }: AxiosResponse<TypeProject> = await updateProject(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getProjects'],
      });
      queryClient.refetchQueries({
        queryKey: ['getProjectById'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useCompleteOnboarding = (
  opts?: Partial<
    UseMutationOptions<Awaited<TypeProject>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['completeOnboarding'],
    mutationFn: async (input: CompleteCreateProjectInput) => {
      const { data }: AxiosResponse<TypeProject> =
        await completeOnboarding(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getProjects'],
      });
      queryClient.refetchQueries({
        queryKey: ['getProjectById'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useGetProjectMembers = (
  input: GetProjectMembersInput,
  opts?: Partial<
    UseQueryOptions<Awaited<TypeProjectMember[]>, DefaultQueryError>
  >
) =>
  useQuery({
    queryKey: ['getProjectMembers', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeProjectMember[]> =
        await getProjectMembers(input);

      return data;
    },
    ...opts,
  });

export const useGetProjectSharedModels = (
  input: GetProjectSharedModelsInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeModel[]>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getProjectSharedModels', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeModel[]> =
        await getProjectSharedModels(input);

      return data;
    },
    ...opts,
  });

export const useUpdateProjectMember = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['updateProjectMember'],
    mutationFn: async (input: UpdateProjectMemberInput) => {
      const { data }: AxiosResponse = await updateProjectMember(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({
        queryKey: ['getOrgMemberById', variables],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};
export const useDeleteProjectMember = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['deleteProjectMember'],
    mutationFn: async (input: DeleteProjectMemberInput) => {
      const { data }: AxiosResponse = await deleteProjectMember(input);

      return data;
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ['getOrgMemberById'],
      });
    },
    ...opts,
  });
};
