import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  DataGridPremium,
  type GridColDef,
  useGridApiRef,
} from '@mui/x-data-grid-premium';

import {
  useGetDatasetInputFileById,
  useGetDatasetInputFiles,
  useGetDatasetInputFilesData,
} from '../hooks/useDatasetsHook';

import FormSelect from './FormSelect';

type FileDataTableProps = {
  columns: {
    excel_name: string;
    is_pinned: boolean;
    name: string;
  }[];
  isLoading: boolean;
  data: Record<string, (string | number)[]> | undefined;
};

const FileDataTable = ({ columns, data, isLoading }: FileDataTableProps) => {
  const apiRef = useGridApiRef();

  const cols = columns.map(({ excel_name, is_pinned, name }) => ({
    field: name,
    headerName: excel_name,
    flex: 100,
    minWidth: 100,
    align: 'right',
    headerAlign: 'center',
    hideable: false,
    sortable: false,
    pinnable: is_pinned,
    filterable: false,
    style: {
      backgroundColor: 'black',
    },
    disableColumnMenu: true,
    renderCell: (params) => (
      <div className="text-center w-full">{params.value}</div>
    ),
  })) as GridColDef[];

  const rows = data
    ? columns.reduce(
        (acc, cur) => {
          const { name } = cur;

          data[name].forEach(
            (value, index) =>
              (acc[index] = {
                id: index,
                ...acc[index],
                [name]: value,
              })
          );

          return acc;
        },
        [] as Record<string, string | number>[]
      )
    : [];

  return (
    <DataGridPremium
      apiRef={apiRef}
      rows={rows}
      columns={cols}
      pagination={false}
      disableSelectionOnClick
      loading={isLoading}
      disableAggregation
      density="compact"
      initialState={{ aggregation: { model: { gross: 'sum' } } }}
      pinnedColumns={{
        left: cols.filter((col) => col.pinnable).map((col) => col.field),
      }}
      defaultGroupingExpansionDepth={2}
      experimentalFeatures={{ rowPinning: true }}
    />
  );
};

const ViewFilesDataTable = () => {
  const form = useForm();
  const [searchParams] = useSearchParams();

  const { org_id } = useParams();

  const orgId = Number(org_id);
  const projectId = Number(searchParams.get('project_id'));

  const { watch, setValue } = form;

  const fileId = watch('fileName');
  const tableNameId = watch('tableName');
  const datasetId = Number(searchParams.get('dataset_id'));

  const { data: inputFiles } = useGetDatasetInputFiles(
    {
      orgId,
      projectId,
      datasetId,
    },
    {
      enabled: !!orgId && !!projectId && !!datasetId,
    }
  );

  const { data: inputFileById } = useGetDatasetInputFileById(
    {
      orgId,
      projectId,
      datasetId,
      fileId,
    },
    {
      enabled: !!orgId && !!projectId && !!datasetId && !!fileId,
    }
  );

  const fileNames = useMemo(
    () =>
      inputFiles?.map((file) => ({
        label: file.name.replace('.xlsx', ''),
        value: file.id,
      })),
    [inputFiles]
  );

  const tableNames = useMemo(
    () =>
      inputFileById?.schema.map((s) => ({
        label: s.excel_tab_name,
        value: s.name,
      })),
    [inputFileById]
  );

  const {
    data: inputFilesData,
    isFetching: isFetchingData,
    isRefetching: isRefetchingData,
  } = useGetDatasetInputFilesData(
    {
      orgId,
      projectId,
      datasetId,
      fileId,
      dataTableName: tableNameId,
    },
    {
      enabled:
        !!orgId && !!projectId && !!datasetId && !!fileId && !!tableNameId,
    }
  );

  useEffect(() => {
    if (inputFiles?.length) {
      setValue('fileName', inputFiles[0].id);
    }
  }, [inputFiles]);

  useEffect(() => {
    if (inputFileById) {
      setValue('tableName', inputFileById.schema[0].name);
    }
  }, [inputFileById, fileId]);

  const columns = inputFileById?.schema.find(
    (s) => s.name === tableNameId
  )?.columns;

  return (
    <div className="space-y-4">
      <FormProvider {...form}>
        <form>
          <div className="flex max-w-[50%] gap-3">
            {fileNames && (
              <FormSelect
                name="fileName"
                fullWidth
                defaultValue={inputFiles?.[0]?.id}
                options={fileNames}
                label="File Name"
              />
            )}
            {tableNames && (
              <FormSelect
                name="tableName"
                fullWidth
                defaultValue={inputFileById?.schema[0].name}
                options={tableNames}
                label="Table Name"
              />
            )}
          </div>
        </form>
      </FormProvider>
      {columns && (
        <div className="h-[350px]">
          <FileDataTable
            columns={columns}
            data={inputFilesData}
            isLoading={isFetchingData || isRefetchingData}
          />
        </div>
      )}
    </div>
  );
};

export default ViewFilesDataTable;
