import { type Granularity } from '../common/dataTypes';
import { type LaborGroup } from '../types';
import { mainApi } from '../utils/api';

export type UpdateDatasetInput = {
  datasetId: number;
  orgId: number;
  projectId: number;
  data: {
    name?: string;
    description?: string;
    project: number;
  };
};

export type CreateDatasetInput = {
  orgId: number;
  projectId: number;
  data: {
    name: string;
    description?: string;
    project: number;
  };
};

export type CreateIFSConfigInput = {
  orgId: number;
  projectId: number;
  data: IFSConfiguration;
};

export type UpdateIFSConfigInput = {
  orgId: number;
  projectId: number;
  data: IFSConfiguration;
};

export type IFSConfiguration = {
  granularity: Granularity;
  start_period: string;
  duration: number;
  aircrafts: any;
  labor_group: LaborGroup;
  positions: any;
  years_of_service: number;
  retirement_age: number;
};

export type GetDatasetsInput = {
  projectId: number;
  orgId: number;
};

export type GetDatasetByIdInput = {
  orgId: number;
  projectId: number;
  datasetId: number;
};

export type GetIFSConfigInput = {
  orgId: number;
  projectId: number;
};

export type DeleteDatasetInput = {
  datasetId: number;
  orgId: number;
  projectId: number;
};

export type GetDatasetInputFilesInput = {
  orgId: number;
  projectId: number;
  datasetId: number;
};

export type GetDatasetInputFilesByIdInput = {
  orgId: number;
  projectId: number;
  datasetId: number;
  fileId: number;
};

export type GetDatasetInputFilesDataInput = {
  orgId: number;
  projectId: number;
  datasetId: number;
  fileId: number;
  dataTableName: string;
};

export type DownloadDatasetInputFilesByIdInput = {
  orgId: number;
  projectId: number;
  datasetId: number;
  fileId: number;
};

const getDatasets = ({ projectId, orgId }: GetDatasetsInput) =>
  mainApi.get(`organizations/${orgId}/projects/${projectId}/datasets/`);

const getDatasetById = ({ orgId, projectId, datasetId }: GetDatasetByIdInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/datasets/${datasetId}/`
  );

const getIFSConfig = ({ orgId, projectId }: GetIFSConfigInput) =>
  mainApi.get(`organizations/${orgId}/projects/${projectId}/configuration/`);

const createIFSConfig = ({ orgId, projectId, data }: CreateIFSConfigInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/configuration/`,
    data
  );

const updateIFSConfig = ({ orgId, projectId, data }: UpdateIFSConfigInput) =>
  mainApi.patch(
    `organizations/${orgId}/projects/${projectId}/configuration/`,
    data
  );

const deleteDataset = ({ datasetId, projectId, orgId }: DeleteDatasetInput) =>
  mainApi.delete(
    `organizations/${orgId}/projects/${projectId}/datasets/${datasetId}/`
  );

const createDataset = ({ data, orgId, projectId }: CreateDatasetInput) =>
  mainApi.post(`organizations/${orgId}/projects/${projectId}/datasets/`, data);

const updateDataset = ({
  datasetId,
  data,
  orgId,
  projectId,
}: UpdateDatasetInput) =>
  mainApi.patch(
    `organizations/${orgId}/projects/${projectId}/datasets/${datasetId}/`,
    data
  );

const getDatasetInputFiles = ({
  orgId,
  projectId,
  datasetId,
}: GetDatasetInputFilesInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/datasets/${datasetId}/input_files/`
  );

const getDatasetInputFilesById = ({
  orgId,
  projectId,
  datasetId,
  fileId,
}: GetDatasetInputFilesByIdInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/datasets/${datasetId}/input_files/${fileId}/`
  );

const downloadDatasetInputFilesById = ({
  orgId,
  projectId,
  datasetId,
  fileId,
}: DownloadDatasetInputFilesByIdInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/datasets/${datasetId}/input_files/${fileId}/download/`
  );

const getDatasetInputFilesData = ({
  orgId,
  projectId,
  datasetId,
  fileId,
  dataTableName,
}: GetDatasetInputFilesDataInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/datasets/${datasetId}/input_files/${fileId}/data/?data_name=${dataTableName}`
  );

export {
  createDataset,
  createIFSConfig,
  downloadDatasetInputFilesById,
  deleteDataset,
  getDatasetById,
  getDatasetInputFiles,
  getDatasetInputFilesById,
  getDatasetInputFilesData,
  getDatasets,
  getIFSConfig,
  updateDataset,
  updateIFSConfig,
};
