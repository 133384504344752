import {
  useMutation,
  type UseMutationOptions,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';

import {
  cloneScenario,
  type CloneScenarioInput,
  createScenario,
  type CreateScenarioInput,
  deleteScenarioById,
  type DeleteScenarioInput,
  getScenarioById,
  type GetScenarioByIdInput,
  getScenarioData,
  type GetScenarioDataInput,
  getScenarios,
  getScenariosByDataTableName,
  type GetScenariosByDataTableNameInput,
  type GetScenariosInput,
  getScenarioVectors,
  type GetScenarioVectorsInput,
  updateScenarioById,
  type UpdateScenarioByIdInput,
} from '../api/scenarios';
import {
  type TypeModel,
  type TypeScenario,
  type TypeScenarioVector,
} from '../common/dataTypes';

import { type DefaultMutationError, type DefaultQueryError } from './index';

export const useCreateScenario = (
  opts?: Partial<
    UseMutationOptions<Awaited<TypeModel>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['createScenario'],
    mutationFn: async (input: CreateScenarioInput) => {
      const { data }: AxiosResponse<TypeModel> = await createScenario(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getScenariosByDataTableId'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useUpdateScenarioById = (
  opts?: Partial<
    UseMutationOptions<Awaited<TypeModel>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['useUpdateScenarioById'],
    mutationFn: async (input: UpdateScenarioByIdInput) => {
      const { data }: AxiosResponse<TypeModel> =
        await updateScenarioById(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getScenarioById'],
      });
      queryClient.refetchQueries({
        queryKey: ['getScenariosByDataTableId'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useDeleteScenarioById = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationKey: ['deleteScenarioById'],
    mutationFn: async (input: DeleteScenarioInput) => {
      const res: AxiosResponse = await deleteScenarioById(input);

      return res;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getScenariosByDataTableId'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useGetScenarioData = (
  input: GetScenarioDataInput,
  opts?: Partial<
    UseQueryOptions<
      Awaited<Record<string, string | number>[]>,
      DefaultQueryError
    >
  >
) =>
  useQuery({
    queryKey: ['getScenarioData', input],
    queryFn: async () => {
      const { data }: AxiosResponse<Record<string, string | number>[]> =
        await getScenarioData(input);

      return data;
    },
    ...opts,
  });

export const useGetScenarioVectors = (
  input: GetScenarioVectorsInput,
  opts?: Partial<
    UseQueryOptions<Awaited<TypeScenarioVector[]>, DefaultQueryError>
  >
) =>
  useQuery({
    queryKey: ['getScenarioVectors', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeScenarioVector[]> =
        await getScenarioVectors(input);

      return data;
    },
    ...opts,
  });

export const useGetScenarios = (
  input: GetScenariosInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeScenario[]>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getScenarios', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeScenario[]> = await getScenarios(input);

      return data;
    },
    ...opts,
  });

export const useGetScenariosByDataTableName = (
  input: GetScenariosByDataTableNameInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeScenario[]>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getScenariosByDataTableName', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeScenario[]> =
        await getScenariosByDataTableName(input);

      return data;
    },
    ...opts,
  });

export const useGetScenarioById = (
  input: GetScenarioByIdInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeScenario>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getScenarioById', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeScenario> =
        await getScenarioById(input);

      return data;
    },
    ...opts,
  });

export const useCloneScenario = (
  opts?: Partial<
    UseMutationOptions<Awaited<TypeScenario>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['cloneModel'],
    mutationFn: async (input: CloneScenarioInput) => {
      const { data } = await cloneScenario(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getScenariosByDatasetId'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};
