import React, { useEffect, useState } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Info } from 'lucide-react';
import * as z from 'zod';

import FormInput from '../components/FormInput';
import { useLogin } from '../hooks';
import OutlineLogo from '../icons/OutlineLogo';
import { handleQueryError } from '../utils/api';
import cn from '../utils/cn';

import LoginByLinkForm from './LoginByLinkForm';
import PasswordChangeForm from './PasswordChangeForm';

const LoginFormSchema = z.object({
  username: z.string().trim().email(),
  password: z.string().trim().min(8),
});

type LoginFormSchemaType = z.infer<typeof LoginFormSchema>;

const LoginForm = () => {
  const [error, setError] = useState<{ detail: string } | null>(null);
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const form = useForm({
    resolver: zodResolver(LoginFormSchema),
  });

  const { handleSubmit } = form;

  const { mutate: login, isPending } = useLogin({
    onSuccess: () => {
      const from = '/';
      navigate(from, { replace: true });
    },
    onError: (error) => {
      handleQueryError(error, setError);
    },
  });

  const onSubmit: SubmitHandler<any> = (data) => {
    login({ data });
  };

  const handleCTAOnClick = (type: 'login_by_link' | 'reset_password') => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('type', type);
    setSearchParams(newSearchParams);
  };

  const currentYear = new Date().getFullYear();

  return (
    <div
      className={cn(
        'flex absolute w-full pb-10 transition translate-y-[120vh] justify-center duration-500',
        {
          'translate-y-0': !searchParams.get('type'),
        }
      )}
    >
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="border-grey-500 text-white flex w-full max-w-[600px] flex-col items-start rounded-xl border-[2px] px-8 py-6 backdrop-blur-sm"
        >
          <div className="flex flex-col pb-6">
            <h1 className="text-2xl font-bold uppercase">welcome!</h1>
            <p className="text-sm">Login to your account</p>
          </div>
          <div className="w-full space-y-3">
            <FormInput
              fullWidth
              name="username"
              label="Email"
              cta={{
                label: 'Use login link instead',
                onClick: () => handleCTAOnClick('login_by_link'),
              }}
              labelClassName="text-white"
              style={{
                backgroundColor: 'white',
              }}
            />
            <FormInput
              fullWidth
              name="password"
              label="Password"
              labelClassName="text-white"
              cta={{
                label: 'Forgot password?',
                onClick: () => handleCTAOnClick('reset_password'),
              }}
              type="password"
              style={{
                backgroundColor: 'white',
              }}
            />
          </div>
          {error && (
            <div className="flex w-full justify-start gap-2 pt-3 text-[#FF816A]">
              <Info />
              <p>{error.detail}</p>
            </div>
          )}
          <LoadingButton
            type="submit"
            loading={isPending}
            style={{
              backgroundColor: '#B8341B',
              color: 'white',
              width: '100%',
              marginTop: 20,
              padding: '12px 0px',
              borderRadius: '4px',
            }}
          >
            Login
          </LoadingButton>
          <div className="w-full pt-4 text-center font-light">
            <p>@{currentYear} ISBE Group</p>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

const Login = () => {
  useEffect(() => {
    document.title = 'Login | ISBE Group';
  }, []);

  return (
    <div className="space-y-4 bg-background bg-cover px-[40px] pt-7 3xl:h-screen 3xl:overflow-hidden">
      <div className="text-white flex items-center justify-start gap-3">
        <OutlineLogo />
        <p className="font-garamond text-[22px] font-bold">ISBE Group</p>
      </div>
      <div className="flex justify-center pb-10">
        <img src="/images/airplane.png" />
      </div>
      <div className="relative h-[75vh] overflow-hidden 3xl:h-full">
        <LoginForm />
        <LoginByLinkForm />
        <PasswordChangeForm />
      </div>
    </div>
  );
};

export default Login;
