import React, { type SyntheticEvent, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Button, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { toArray } from 'lodash';
import { Plus } from 'lucide-react';

import { useGetPreDefinedExpenses } from '../../hooks/useExpenseHook';
import AddExpenseFormDialog, {
  type AddExpenseFormSchemaType,
} from '../expense/AddExpenseFormDialog';

export type StyledTabsProps = {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
};

export type StyledTabProps = {
  label: string;
  id: string;
};

export type TabPanelProps = {
  children?: React.ReactNode;
  index: string;
  value: string;
};

const Tabs = styled((props: StyledTabsProps) => (
  <MuiTabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  gap: '12px !important',
  backgroundColor: '#F5F9FF',

  '& .MuiTabs-indicator': {
    display: 'flex',
    gap: '12px !important',
    justifyContent: 'center',
    backgroundColor: '#F5F9FF',
  },

  '& .MuiTabs-flexContainer': {
    paddingLeft: '48px',
    gap: '24px',
  },

  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#01285F',
  },
});

const Tab = styled((props: StyledTabProps) => (
  <MuiTab disableRipple {...props} />
))({
  fontFamily: 'Poppins, sans-serif',
  textTransform: 'none',
  padding: '0px !important',
  minWidth: 'fit-content !important',

  '&.MuiButtonBase-root': {
    padding: '0px !important',
  },
  '&.Mui-selected': {
    color: '#01285F',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'transparent',
  },
});

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="space-y-6 px-12 py-6"
      role="tabpanel"
      hidden={value !== index}
      id={value}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

export const TABS = ['Custom Expenses', 'Predefined Expenses'];

const ProjectExpensesManagement = () => {
  const [index, setIndex] = useState(0);
  const [openCreateExpenseDialog, setOpenCreateExpenseDialog] = useState(false);

  const { org_id } = useParams();

  const orgId = Number(org_id);

  const { data: preDefinedExpenses } = useGetPreDefinedExpenses(
    {
      orgId,
    },
    {
      enabled: !!orgId,
    }
  );

  const categories = preDefinedExpenses
    ? (
        toArray(
          new Set(preDefinedExpenses.map((e) => e.group.name))
        ) as string[]
      ).flatMap((i) => ({
        value: i,
        label: i,
      }))
    : null;

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setIndex(newValue);
  };

  const handleOpenCreateExpenseDialog = () => {
    setOpenCreateExpenseDialog(true);
  };

  const handleCloseCreateExpenseDialog = () => {
    setOpenCreateExpenseDialog(false);
  };

  const form = useForm();

  const { setValue } = form;

  const handleAddExpense = (input: AddExpenseFormSchemaType) => {
    const { category, name } = input;

    setValue(`user.${category}.${name}`, true);
  };

  return (
    <div className="text-2xl font-bold">
      <Tabs value={index} onChange={handleChange}>
        {TABS.map((report) => (
          <Tab id={report} key={report} label={report} />
        ))}
      </Tabs>
      <CustomTabPanel value={index.toString()} index="0">
        <FormProvider {...form}>
          <form className="flex flex-col">
            <div className="flex justify-between">
              <h1 className="text-2xl font-bold">Custom Expenses</h1>
              {categories && (
                <>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: '#B8341B',
                    }}
                  >
                    <div className="flex cursor-pointer items-center gap-1">
                      <Plus width={20} height={20} />
                      <label className="cursor-pointer">Add</label>
                    </div>
                  </Button>
                  <AddExpenseFormDialog
                    title="Add Custom Expense"
                    categories={categories}
                    onSubmit={handleAddExpense}
                    open={openCreateExpenseDialog}
                    handleOpen={handleOpenCreateExpenseDialog}
                    handleClose={handleCloseCreateExpenseDialog}
                  />
                </>
              )}
            </div>
          </form>
        </FormProvider>
      </CustomTabPanel>
      <CustomTabPanel value={index.toString()} index="1">
        <div>Hello 1</div>
      </CustomTabPanel>
    </div>
  );
};

export default ProjectExpensesManagement;
