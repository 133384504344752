import React, { type SyntheticEvent, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';
import { styled } from '@mui/material/styles';

import { type TypeDataset } from '../common/dataTypes';
import { useGetIFS } from '../hooks/useIFSHook';

import ReviewIFSTable from './dataset/ReviewIFSTable';
import {
  type StyledTabProps,
  type StyledTabsProps,
  type TabPanelProps,
} from './projects/ProjectExpensesManagement';
import ViewFilesDataTable from './ViewFilesDataTable';

const TABS = ['Data files', 'View Data'];

type ReviewIFSFilesTabsProps = {
  dataset: TypeDataset;
};

const Tabs = styled((props: StyledTabsProps) => (
  <MuiTabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  gap: '12px !important',

  '& .MuiTabs-indicator': {
    display: 'flex',
    gap: '12px !important',
    justifyContent: 'center',
  },

  '& .MuiTabs-flexContainer': {
    gap: '24px',
  },

  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#01285F',
  },
});

const Tab = styled((props: StyledTabProps) => (
  <MuiTab disableRipple {...props} />
))({
  fontFamily: 'Poppins, sans-serif',
  textTransform: 'none',
  padding: '0px !important',
  minWidth: 'fit-content !important',

  '&.MuiButtonBase-root': {
    padding: '0px !important',
  },
  '&.Mui-selected': {
    color: '#01285F',
    fontWeight: 500,
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'transparent',
  },
});

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="space-y-6"
      role="tabpanel"
      hidden={value !== index}
      id={value}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

const ReviewIFSFilesTabs = ({ dataset }: ReviewIFSFilesTabsProps) => {
  const [index, setIndex] = useState(0);

  const { org_id } = useParams();
  const [searchParams] = useSearchParams();

  const projectId = Number(searchParams.get('project_id'));

  const orgId = Number(org_id);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setIndex(newValue);
  };

  const { data: ifs } = useGetIFS(
    {
      projectId,
      datasetId: dataset.id,
      orgId,
    },
    {
      enabled: !!dataset && !!orgId && !!projectId,
    }
  );

  return (
    <div className="flex flex-col gap-4">
      <Tabs value={index} onChange={handleChange}>
        {TABS.map((report) => (
          <Tab id={report} key={report} label={report} />
        ))}
      </Tabs>
      <CustomTabPanel value={index.toString()} index="0">
        {ifs && projectId && (
          <ReviewIFSTable
            projectId={projectId}
            dataset={dataset}
            ifs={{
              ...ifs,
              files: ifs.files,
            }}
          />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={index.toString()} index="1">
        <ViewFilesDataTable />
      </CustomTabPanel>
    </div>
  );
};

export default ReviewIFSFilesTabs;
