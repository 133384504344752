import React, { type CSSProperties, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  closestCenter,
  DndContext,
  type DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  type UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Add, DragHandle } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Checkbox as MuiCheckbox, styled } from '@mui/material';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  type Row,
  useReactTable,
} from '@tanstack/react-table';
import _ from 'lodash';

import IconButton from '../../../common/IconButton';
import Delete from '../../../icons/Delete';
import cn from '../../../utils/cn';

import AddAircraftDialog from './AddAircraftDialog';
import AddAirportDialog from './AddAirportDialog';
import EditAircraftDialog from './EditAircraftDialog';

export type Aircraft = {
  hierarchy?: number;
  aircraft: string | null;
  airports: string[] | null;
};

const defaultData: Aircraft[] = [
  {
    aircraft: null,
    airports: [''],
    hierarchy: 1,
  },
];

const columnHelper = createColumnHelper<Aircraft>();

const RowDragHandleCell = ({ rowId }: { rowId: string }) => {
  const { attributes, listeners } = useSortable({
    id: rowId,
  });

  return (
    <button type="button" {...attributes} {...listeners}>
      <DragHandle
        style={{
          color: '#666',
        }}
      />
    </button>
  );
};

const DraggableRow = ({
  isSelected,
  row,
}: {
  isSelected?: boolean;
  row: Row<Aircraft>;
}) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.original.aircraft as string,
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative',
  };

  return (
    <tr
      ref={setNodeRef}
      className={cn('', {
        'bg-[#F5F9FF]': isSelected,
      })}
      style={style}
    >
      {row.getVisibleCells().map((cell, index) => (
        <td
          key={cell.id}
          style={{ width: cell.column.getSize() }}
          className={cn('border border-[#E4E7EC]', {
            'w-[30px]': index === 0,
          })}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  );
};

const AddButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    onClick={onClick}
    style={{
      fontSize: '16px',
      color: '#B8341B',
    }}
  >
    <Add
      style={{
        width: '16px',
        height: '16px',
      }}
    />{' '}
    Add
  </Button>
);

const Checkbox = styled(MuiCheckbox)({
  padding: 0,
});

const EditBar = () => {
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  const { watch, setValue, resetField } = useFormContext();

  const selectedAircrafts = watch('selectedAircrafts');
  const aircrafts = watch('aircrafts');

  delete selectedAircrafts['null'];

  useEffect(() => {
    const indeterminate =
      selectedAircrafts &&
      Object.keys(selectedAircrafts).some((key) => selectedAircrafts[key]);

    setIsIndeterminate(indeterminate);
  }, [selectedAircrafts]);

  const selectedCount = Object.values(selectedAircrafts).filter(
    (boolean) => boolean
  ).length;

  const handleOnClick = () => {
    setIsIndeterminate(false);
    resetField('selectedAircrafts');
  };

  const handleDelete = () => {
    const updateAircrafts = aircrafts
      .filter(
        (aircraft: { aircraft: string }) =>
          !selectedAircrafts[aircraft.aircraft]
      )
      .flatMap((a: Aircraft, i: number) => ({
        ...a,
        hierarchy: i + 1,
      }));

    resetField('selectedAircrafts');
    setValue('aircrafts', updateAircrafts, {
      shouldDirty: false,
    });
  };

  return (
    <div className="flex h-[41.70px] w-full items-center justify-between border border-b-0 border-[#E4E7EC] bg-[#F5F9FF] pl-3 pr-6">
      <div className="flex gap-2">
        <Checkbox onClick={handleOnClick} indeterminate={isIndeterminate} />
        <p className="font-bold text-[#4D4D4D]">{selectedCount} selected</p>
      </div>
      <div className="flex grow justify-end gap-4 font-semibold text-[#666]">
        <IconButton icon={<Delete />} onClick={handleDelete} />
      </div>
      <div />
    </div>
  );
};

type AircraftTableProps = {
  defaultValue?: Aircraft[];
};

const getColumns = ({
  handleOpenDialogAddAircraft,
  handleOpenDialogAddAirport,
}: {
  handleOpenDialogAddAircraft: () => void;
  handleOpenDialogAddAirport: (aircraft: string) => void;
}) => [
  columnHelper.accessor('hierarchy', {
    cell: (info) => {
      const [isSelected, setIsSelected] = useState(false);
      const { register, watch } = useFormContext();

      const aircraft = info.row.original.aircraft;
      const selectedAircrafts = watch('selectedAircrafts');

      const isAircraftChecked =
        !!aircraft && !!selectedAircrafts && selectedAircrafts[aircraft];

      useEffect(() => {
        setIsSelected(isAircraftChecked);
      }, [isAircraftChecked]);

      if (!info.getValue() || !info.row.original.aircraft) {
        return <></>;
      }

      return (
        <div className="pl-3 text-start">
          <label
            htmlFor={info.row.original.aircraft}
            className="group flex w-[120px] cursor-pointer items-center gap-2 py-3"
          >
            <Checkbox
              id={info.row.original.aircraft}
              {...register(`selectedAircrafts.${info.row.original.aircraft}`)}
              checked={isSelected}
              className={cn('transition opacity-0 group-hover:opacity-100', {
                'opacity-100': isSelected,
              })}
            />
            <p className="w-[40%] text-center">{info.getValue()}</p>
          </label>
        </div>
      );
    },
    header: () => {
      const [isSelected, setIsSelected] = useState(false);
      const { watch, setValue } = useFormContext();

      const aircrafts = watch('aircrafts');
      const selectedAircrafts = watch('selectedAircrafts');

      useEffect(() => {
        setIsSelected(
          !_.isEmpty(selectedAircrafts ?? {}) &&
            _.isEmpty(aircrafts) &&
            Object.keys(selectedAircrafts)?.filter((a) => !aircrafts[a])
              .length === 0
        );
      }, [selectedAircrafts, aircrafts]);

      const handleOnClick = () => {
        setValue(
          'selectedAircrafts',
          aircrafts.reduce(
            (
              acc: { [x: string]: boolean },
              curr: { aircraft: string | number }
            ) => {
              acc[curr.aircraft] = true;
              return acc;
            },
            {}
          )
        );
      };

      const aircraftsExist = aircrafts?.filter(
        (a: Aircraft) => a.aircraft !== null
      ).length;

      return (
        <div className="flex items-center gap-2 pl-3">
          <div
            className={cn('opacity-0', {
              'opacity-100': aircraftsExist,
            })}
          >
            <Checkbox
              checked={isSelected}
              onClick={handleOnClick}
              disabled={!aircraftsExist}
            />
          </div>
          <p>Hierarchy</p>
        </div>
      );
    },
  }),
  columnHelper.accessor('aircraft', {
    cell: (info) => {
      if (!info.getValue() || !info.row.original.aircraft) {
        return (
          <div className="flex justify-center">
            <AddButton onClick={handleOpenDialogAddAircraft} />
          </div>
        );
      }

      return (
        <div className="group flex w-full cursor-pointer items-center justify-center gap-2 px-2 py-3">
          {info.getValue()}
        </div>
      );
    },
    header: () => (
      <div className="flex justify-center gap-2">
        <p>Aircraft</p>
      </div>
    ),
  }),
  columnHelper.accessor('airports', {
    id: 'drag-handle',
    cell: (info) => {
      const aircraft = info.row.getValue('aircraft');

      const original = info.row.original as {
        aircraft: string;
        airports: string[];
      };

      const value = info.getValue() as string[] | null;

      const showAddButton = value?.length === 1 && value?.[0] === '';

      return aircraft ? (
        <>
          <div className="flex justify-between">
            {!showAddButton && (
              <p className="pl-6">
                {value
                  ?.filter((v) => v?.length > 0)
                  .sort()
                  .join(', ')}
              </p>
            )}
            {showAddButton && (
              <div className="pl-2">
                <AddButton
                  onClick={() => {
                    handleOpenDialogAddAirport(aircraft as string);
                  }}
                />
              </div>
            )}
            <div className="flex gap-2 pr-6">
              <EditAircraftDialog defaultValues={original} />
              {info.row.id && <RowDragHandleCell rowId={info.row.id} />}
            </div>
          </div>
        </>
      ) : null;
    },
    header: () => <div className="w-full px-6 text-start">Airports</div>,
    size: 1000,
  }),
];

const AircraftTable = ({ defaultValue }: AircraftTableProps) => {
  const [data, setData] = useState(defaultData);
  const [openDialogAddAircraft, setOpenDialogAddAircraft] = useState(false);
  const [openDialogAddAirport, setOpenDialogAddAirport] = useState(false);
  const [aircrafts, setAircrafts] = useState<string[]>([]);
  const [airports, setAirports] = useState<string[]>(['']);
  const [selectedAircraft, setSelectedAircraft] = useState<string | null>(null);

  const { watch, setValue: setFormValue } = useFormContext();

  const dataIds = React.useMemo<UniqueIdentifier[]>(
    () =>
      data
        ?.filter(({ aircraft }) => !!aircraft)
        .map(({ aircraft }) => aircraft as string),
    [data]
  );

  const selectedAircrafts = watch('selectedAircrafts');
  const formAircrafts = (watch('aircrafts') as Aircraft[]) ?? [];

  const indeterminateAircrafts = selectedAircrafts
    ? Object.keys(selectedAircrafts).filter((key) => selectedAircrafts[key])
    : [];

  const isIndeterminate = !!indeterminateAircrafts.length;

  useEffect(() => {
    if (aircrafts.length === 0) {
      setData(defaultData);
      return;
    }

    const result = [
      ...data.filter((item) => item.aircraft !== null),
      ...aircrafts.map((aircraft) => ({
        aircraft,
        airports: [''],
      })),
      ...defaultData,
    ].map((item, index) => ({
      ...item,
      hierarchy: index + 1,
    }));

    setFormValue('aircrafts', result, {
      shouldDirty: defaultValue
        ? !_.isEqual(result, defaultValue)
        : !_.isEqual(result, defaultData),
    });
  }, [aircrafts]);

  useEffect(() => {
    const result = [
      ...data.map((item) => {
        if (item.aircraft === selectedAircraft) {
          return {
            ...item,
            airports,
          };
        }
        return item;
      }),
    ].map((item, index) => ({
      ...item,
      hierarchy: index + 1,
    }));

    setFormValue('aircrafts', result, {
      shouldDirty: false,
    });
  }, [airports]);

  useEffect(() => {
    const initialState = data.find((r) => r.aircraft === null);

    if (!initialState) {
      setData([...formAircrafts, ...defaultData]);
      return;
    }

    setData(formAircrafts);
  }, [formAircrafts]);

  useEffect(() => {
    if (defaultValue?.length) {
      const data = [...defaultValue, ...defaultData].map((item, index) => ({
        ...item,
        hierarchy: index + 1,
      }));

      setFormValue('aircrafts', data, {
        shouldDirty: defaultValue
          ? !_.isEqual(
              data.filter((d) => d.aircraft),
              defaultValue
            )
          : !_.isEqual(data, defaultData),
      });
    }
  }, [defaultValue]);

  const handleOpenDialogAddAircraft = () => {
    setOpenDialogAddAircraft(true);
  };

  const handleCloseDialogAddAircraft = () => {
    setOpenDialogAddAircraft(false);
  };

  const handleOpenDialogAddAirport = (aircraft: string) => {
    setSelectedAircraft(aircraft);
    setOpenDialogAddAirport(true);
  };

  const handleCloseDialogAddAirport = () => {
    setOpenDialogAddAirport(false);
  };

  const handleAddAircraft = (input: string[]) => {
    setAircrafts(input);
    handleCloseDialogAddAircraft();
  };

  const handleAddAirport = (input: string[]) => {
    setAirports(input);
    handleCloseDialogAddAirport();
  };

  const columns = useMemo(
    () =>
      getColumns({
        handleOpenDialogAddAircraft,
        handleOpenDialogAddAirport,
      }),
    []
  );

  const table = useReactTable({
    data,
    getRowId: (row) => row.aircraft ?? '',
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
  });

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      setData((data) => {
        const oldIndex = dataIds.indexOf(active.id);
        const newIndex = dataIds.indexOf(over.id);

        const newData = arrayMove(data, oldIndex, newIndex).map(
          (item, index) => ({
            ...item,
            hierarchy: index + 1,
          })
        );

        setFormValue('aircrafts', newData);

        return newData;
      });
    }
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <div>
        {isIndeterminate && <EditBar />}
        <table className="w-full">
          <thead className={cn({ hidden: isIndeterminate })}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className="border border-[#E4E7EC] bg-[#F5F9FF]"
              >
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="border border-[#E4E7EC] py-2 text-sm text-[#4D4D4D]"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            <SortableContext
              items={dataIds}
              strategy={verticalListSortingStrategy}
            >
              {table.getRowModel().rows.map((row) => {
                const isSelected =
                  !!row.original.aircraft &&
                  indeterminateAircrafts.includes(row.original.aircraft);

                return (
                  <DraggableRow
                    key={row.id ?? ''}
                    isSelected={isSelected}
                    row={row}
                  />
                );
              })}
            </SortableContext>
          </tbody>
        </table>
      </div>
      <AddAircraftDialog
        defaultValue={data
          .filter((a) => !!a.aircraft)
          .map((a) => a.aircraft?.toLowerCase() as string)}
        open={openDialogAddAircraft}
        handlePrimaryAction={handleAddAircraft}
        handleSecondaryAction={handleCloseDialogAddAircraft}
      />
      <AddAirportDialog
        open={openDialogAddAirport}
        handlePrimaryAction={handleAddAirport}
        handleSecondaryAction={handleCloseDialogAddAirport}
      />
    </DndContext>
  );
};

export default AircraftTable;
