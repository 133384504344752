import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { MenuItem } from '@mui/material';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import { Archive, Copy, EllipsisVertical, Pencil, Trash2 } from 'lucide-react';

import { type TypeDataset } from '../../common/dataTypes';
import { useGetDatasetById, useGetDatasets } from '../../hooks';
import { useCreateDataset } from '../../hooks/useDatasetsHook';
import cn from '../../utils/cn';
import ConfirmDeleteDialog from '../ConfirmDeleteDialog';
import MenuDropdown from '../MenuDropdown';
import Spinner from '../Spinner';

import CreateDatasetDialog from './CreateDatasetDialog';

export type DatasetRow = {
  id: number;
  name: string;
  description: string | undefined;
  modified: string | undefined;
  createdBy: string;
};

const defaultData: DatasetRow[] = [];

const columnHelper = createColumnHelper<DatasetRow>();

const getCopyDatasetName = ({
  name,
  datasets,
  dataset,
}: {
  datasets: TypeDataset[];
  dataset: TypeDataset;
  name: string;
}) => {
  const getCopyVersion = (str: string) => {
    const match = str.match(/\((\d+)\)$/);
    return match ? match[1] : null;
  };

  const updateCopyVersion = (str: string, newNumber: number) =>
    str.replace(/\((\d+)\)$/, `(${newNumber})`);

  const maxCopyVersion = datasets
    ? Math.max(...datasets.map((d) => Number(getCopyVersion(d.name))))
    : 0;

  const currentVersion = Number(getCopyVersion(name));

  const latestVersionDataset =
    datasets?.find((d) =>
      maxCopyVersion === 0
        ? d.name === name.concat(`(${maxCopyVersion})`)
        : d.name === name
    ) ?? dataset;

  return maxCopyVersion !== 0 && currentVersion !== 0
    ? updateCopyVersion(latestVersionDataset?.name ?? '', maxCopyVersion + 1)
    : name.concat(`(${maxCopyVersion + 1})`);
};

const columns = ({ handleSelect }: { handleSelect: (id: number) => void }) => [
  columnHelper.accessor('name', {
    cell: (info) => (
      <div className="group flex items-center gap-2 py-3 text-start">
        <p
          onClick={() => handleSelect(info.row.original.id)}
          className="w-full"
        >
          {info.getValue()}
        </p>
      </div>
    ),
    header: () => (
      <div className="flex items-center gap-2">
        <p>Name</p>
      </div>
    ),
  }),
  columnHelper.accessor('description', {
    id: 'description',
    cell: (info) => {
      const id = info.row.original.id;

      return info.getValue() ? (
        <div
          onClick={() => handleSelect(id)}
          className="line-clamp-5 max-w-[250px] 2xl:max-w-[350px]"
        >
          {info.getValue()}
        </div>
      ) : (
        <div
          onClick={() => handleSelect(id)}
          className="text-gray-400 max-w-[250px] text-start"
        >
          --
        </div>
      );
    },
    header: () => <div>Description</div>,
  }),
  columnHelper.accessor('modified', {
    id: 'modified',
    cell: (info) => {
      const id = info.row.original.id;

      return info.getValue() ? (
        <div onClick={() => handleSelect(id)}>
          <p>{format(info.getValue() as string, 'Y/MM/dd')}</p>
        </div>
      ) : (
        <div
          onClick={() => handleSelect(id)}
          className="text-gray-400 text-start"
        >
          --
        </div>
      );
    },
    header: () => <div>Last Modified</div>,
  }),
  columnHelper.accessor('createdBy', {
    id: 'createdBy',
    cell: (info) => {
      const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

      const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] =
        useState(false);

      const [openCreateProjectDialog, setOpenCreateProjectDialog] =
        useState(false);

      const [openInviteFormDialog, setOpenInviteFormDialog] = useState(false);

      const { org_id, project_id } = useParams();

      const open = Boolean(anchorEl);

      const datasetId = info.row.original.id;
      const orgId = Number(org_id);
      const projectId = Number(project_id);

      const { data: dataset } = useGetDatasetById(
        {
          datasetId,
          orgId,
          projectId,
        },
        {
          enabled: !!orgId && !!datasetId && !!projectId,
        }
      );

      const { data: datasets } = useGetDatasets(
        {
          projectId,
          orgId,
        },
        {
          enabled: !!projectId && !!orgId,
        }
      );

      const { mutateAsync: createDataset } = useCreateDataset();

      const handleCloseDropdown = () => setAnchorEl(null);

      const handleOpenConfirmDeleteDialog = () => {
        setOpenConfirmDeleteDialog(true);
      };

      const handleCloseConfirmDeleteDialog = () => {
        setOpenConfirmDeleteDialog(false);
      };

      const handleOpenCreateProjectDialog = () => {
        setOpenCreateProjectDialog(true);
      };

      const handleCloseCreateProjectDialog = () => {
        setOpenCreateProjectDialog(false);
      };

      const handleCloseInviteFormDialog = () => {
        setOpenInviteFormDialog(false);
      };

      const handleOpenInviteFormDialog = () => {
        setOpenInviteFormDialog(true);
      };

      const handleDuplicateDataset = useCallback(() => {
        if (datasets && dataset) {
          const { project, name, description } = dataset;

          const datasetData = {
            project,
            name: getCopyDatasetName({
              name,
              datasets,
              dataset,
            }),
            description,
          };

          createDataset({
            orgId,
            projectId: project,
            data: datasetData,
          });
        }
      }, [dataset, orgId]);

      return info.getValue() ? (
        <div className="flex min-w-[200px] justify-between">
          <p
            className="max-w-[180px] grow truncate"
            onClick={() => handleSelect(projectId)}
          >
            {info.getValue()}
          </p>
          <MenuDropdown
            trigger={
              <button>
                <EllipsisVertical color="#666666" width={20} height={20} />
              </button>
            }
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            style={{
              marginTop: '5px',
            }}
            open={open}
          >
            <MenuItem
              onClick={() => {
                handleCloseDropdown();
                handleDuplicateDataset();
              }}
            >
              <div className="flex items-center space-x-2">
                <Copy size={15} />
                <p>Duplicate</p>
              </div>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleCloseDropdown();
                handleOpenCreateProjectDialog();
              }}
            >
              <div className="flex items-center space-x-2">
                <Pencil size={15} />
                <p>Edit</p>
              </div>
            </MenuItem>
            <MenuItem>
              <div className="flex items-center space-x-2">
                <Archive size={15} />
                <p>Archive</p>
              </div>
            </MenuItem>
            <div className="!border-t !border-[#E4E7EC]" />
            <MenuItem
              onClick={() => {
                handleCloseDropdown();
                handleOpenConfirmDeleteDialog();
              }}
            >
              <div className="flex items-center space-x-2 text-primary-red">
                <Trash2 size={15} />
                <p>Delete</p>
              </div>
            </MenuItem>
          </MenuDropdown>
          {dataset && (
            <CreateDatasetDialog
              open={openCreateProjectDialog}
              defaultValues={{
                id: dataset.id,
                name: dataset.name,
                description: dataset.description,
              }}
              handleClose={handleCloseCreateProjectDialog}
            />
          )}
          {dataset && (
            <ConfirmDeleteDialog
              type="dataset"
              row={{
                id: dataset.id,
                name: dataset.name,
                description: dataset.description,
                modified: dataset.updated_at,
              }}
              open={openConfirmDeleteDialog}
              handleOpen={handleOpenConfirmDeleteDialog}
              handleClose={handleCloseConfirmDeleteDialog}
            />
          )}
        </div>
      ) : (
        <div
          onClick={() => handleSelect(projectId)}
          className="text-gray-400 text-start"
        >
          --
        </div>
      );
    },
    header: () => <div>Created by</div>,
  }),
];

const DatasetsTable = () => {
  const { project_id, org_id } = useParams();
  const [data, setData] = useState<DatasetRow[]>(defaultData);

  const projectId = Number(project_id);
  const orgId = Number(org_id);

  const navigate = useNavigate();

  const {
    data: datasets,
    isSuccess,
    isRefetching,
  } = useGetDatasets(
    {
      projectId,
      orgId,
    },
    {
      enabled: !!projectId && !!orgId,
    }
  );

  const handleSelect = (id: number) => {
    navigate(id.toString());
  };

  useEffect(() => {
    if (isSuccess) {
      setData(
        datasets.map((dataset) => ({
          id: dataset.id,
          name: dataset.name,
          description: dataset.description,
          modified: dataset.updated_at,
          createdBy: 'Nate Nguyen',
        }))
      );
    }
  }, [datasets, isSuccess]);

  const table = useReactTable({
    data,
    columns: columns({
      handleSelect,
    }),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <table className="w-full">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr
            key={headerGroup.id}
            className="!rounded-md border-[#E4E7EC] bg-[#F8F8F8] first:border-l last:border-r"
          >
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                className={cn(
                  'first:border-l border-t border-b last:border-r border-[#E4E7EC] px-6 py-3 text-start text-sm text-[#4D4D4D]'
                )}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="relative">
        {isRefetching && (
          <div className="bg-gray-200 absolute flex h-full w-full items-center justify-center opacity-50">
            <Spinner />
          </div>
        )}
        {table.getRowModel().rows.map((row) => (
          <tr
            key={row.id}
            className="cursor-pointer py-3 text-[#333] transition"
          >
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                className="border-b border-[#E4E7EC] px-6 first:border-l last:border-r"
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DatasetsTable;
