import React, { useEffect } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Plus } from 'lucide-react';
import { z } from 'zod';

import { useGetDatasetById } from '../../hooks/useDatasetsHook';
import { useGetDataTables } from '../../hooks/useDataTablesHook';
import {
  useCreateScenario,
  useUpdateScenarioById,
} from '../../hooks/useScenariosHook';
import { useCurrentOrganization } from '../../utils/helpers';
import FormInput from '../FormInput';

type CreateScenarioFormDialogProps = {
  open: boolean;
  title: string;
  showTrigger?: boolean;
  trigger?: {
    label: React.ReactNode;
    style?: React.CSSProperties;
    onClick?: () => void;
  };
  defaultValues?: {
    id: number;
    name: string;
    description?: string;
  };
  dataset?: string;
  dataTable?: string;
  handleCloseDialog: () => void;
  handleOpenDialog: () => void;
};

const CreateScenarioSchema = z.object({
  name: z.string().trim(),
  description: z.string().trim().optional(),
  dataset: z.string().trim(),
  dataTable: z.string().trim(),
});

type CreateScenarioSchemaType = z.infer<typeof CreateScenarioSchema>;

const CreateScenarioFormDialog = ({
  open,
  title,
  trigger,
  showTrigger = true,
  defaultValues,
  handleOpenDialog,
  handleCloseDialog,
}: CreateScenarioFormDialogProps) => {
  const currentOrg = useCurrentOrganization();

  const form = useForm<CreateScenarioSchemaType>({
    resolver: zodResolver(CreateScenarioSchema),
    defaultValues,
  });

  const { formState, handleSubmit, setValue, reset } = form;

  const { isSubmitting } = formState;

  const { project_id, input_table_name, org_id, model_id } = useParams();

  const [searchParams] = useSearchParams();

  const datasetId = Number(searchParams.get('dataset_id'));
  const dataTableId = Number(input_table_name);
  const modelId = Number(model_id);
  const projectId = Number(project_id);
  const orgId = Number(org_id);

  const { data: dataTables } = useGetDataTables(
    {
      orgId,
      projectId,
    },
    {
      enabled: !!orgId && !!projectId,
    }
  );

  const { data: dataset } = useGetDatasetById(
    {
      datasetId,
      orgId,
      projectId,
    },
    {
      enabled: !!datasetId && !!orgId && !!projectId,
    }
  );

  const { mutate: createScenario } = useCreateScenario({
    onSuccess: () => {
      reset();
      handleCloseDialog();
    },
  });

  const { mutate: updateScenario } = useUpdateScenarioById({
    onSuccess: () => {
      reset();
      handleCloseDialog();
    },
  });

  useEffect(() => {
    if (dataset && dataTables) {
      reset({
        ...defaultValues,
        dataset: dataset.name,
        dataTable: dataTables.find((d) => d.id === Number(dataTableId))
          ?.name as string,
      });
    }
  }, [defaultValues, dataTables, dataset]);

  useEffect(() => {
    if (dataTableId && dataTables && dataTables.length > 0) {
      setValue(
        'dataTable',
        dataTables.find((d) => d.id === Number(dataTableId))?.name as string
      );
    }

    if (datasetId && dataset) {
      setValue('dataset', dataset.name);
    }
  }, [datasetId, dataTableId, dataset, dataTables]);

  const onSubmit: SubmitHandler<CreateScenarioSchemaType> = (data) => {
    if (defaultValues) {
      updateScenario({
        orgId,
        projectId,
        modelId,
        id: defaultValues.id,
        data: {
          name: data.name,
          description: data.description,
          data_table: dataTableId,
        },
      });
      return;
    }

    createScenario({
      orgId,
      projectId,
      modelId,
      data: {
        name: data.name,
        description: data.description,
        data_table: dataTableId,
      },
    });
  };

  return (
    <>
      {showTrigger && (
        <button
          onClick={handleOpenDialog}
          className="text-start"
          type="button"
          style={trigger?.style}
        >
          {trigger ? (
            trigger.label
          ) : (
            <Plus width={20} height={20} color="#E8F2FF" />
          )}
        </button>
      )}
      <Dialog open={open} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle className="w-full">
          <h2 className="px-6 pt-2 text-2xl font-bold">{title}</h2>
        </DialogTitle>
        <DialogContent>
          <div className="z-50 px-6">
            <FormProvider {...form}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="mb-2 space-y-6"
              >
                <div className="space-y-6">
                  <FormInput
                    label="Scenario Name"
                    name="name"
                    required
                    fullWidth
                  />
                  <FormInput label="Description" name="description" fullWidth />
                  {!defaultValues && (
                    <>
                      <FormInput
                        label="Dataset"
                        name="dataset"
                        disabled
                        fullWidth
                      />
                      <FormInput
                        label="BaseTable"
                        name="dataTable"
                        disabled
                        fullWidth
                      />
                    </>
                  )}
                </div>
                <div className="flex-end flex justify-end gap-3">
                  <Button
                    onClick={handleCloseDialog}
                    variant="outlined"
                    style={{
                      color: '#666',
                      borderColor: '#B3B3B3',
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    loading={isSubmitting}
                    variant="contained"
                    style={{
                      backgroundColor: '#2196F3',
                      color: '#FFF',
                    }}
                  >
                    {defaultValues ? 'Save' : 'Create'}
                  </LoadingButton>
                </div>
              </form>
            </FormProvider>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateScenarioFormDialog;
