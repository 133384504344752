import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import {
  type GridColDef,
  type GridColumnGroupingModel,
} from '@mui/x-data-grid-premium';

import {
  generateMonthList,
  generateWeekList,
  generateYearList,
  transformToLabel,
} from '../utils/helpers';

import { type TypeDataTableSchema } from './dataTypes';

export const DATA_DISPLAY_MODE_DEFAULT = 'default';
export const DATA_DISPLAY_MODE_YEARLY = 'yearly';
export const DATA_DISPLAY_MODE_QUARTERLY = 'quarterly';
export const DATA_DISPLAY_MODE_MONTHLY = 'monthly';

export function getSupportedPivotDisplayModes(dsSchema: TypeDataTableSchema) {
  return dsSchema?.pivot?.pivot_columns ?? [];
}

const yearlyPeriodList = generateYearList().map((r) => r.value);

const monthlyPeriodList = generateMonthList()
  .map((m) => generateYearList().map((y) => `${m.value}-${y.value}`))
  .flat();

const weeklyPeriodList = generateWeekList()
  .map((w) =>
    generateMonthList()
      .map((m) => generateYearList().map((y) => `${w}-${m}-${y}`))
      .flat()
  )
  .flat();

const ModelCompareTooltip = ({
  numberA,
  numberB,
  children,
}: {
  numberA: number;
  numberB: number;
  children: React.ReactElement;
}) => (
  <Tooltip
    disableFocusListener
    title={
      <div className="flex gap-3">
        <div className="flex flex-col">
          <span>Model A</span>
          <p>{usNumberFormatter.format(numberA)}</p>
        </div>
        <div className="flex flex-col">
          <span>Model B</span>
          <p>{usNumberFormatter.format(numberB)}</p>
        </div>
        <div className="flex flex-col">
          <span>Difference</span>
          <p>{usNumberFormatter.format(numberA - numberB)}</p>
        </div>
      </div>
    }
  >
    {children}
  </Tooltip>
);

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export function getGroupByColumns(dsSchema: TypeDataTableSchema) {
  return dsSchema?.pivot?.groupby_columns ?? [];
}

export function getAggColumns(dsSchema: TypeDataTableSchema) {
  return dsSchema?.pivot?.aggregate_columns ?? [];
}

export function getColsFromRow(
  row: any,
  dsSchema: TypeDataTableSchema,
  displayMode: string,
  baseRows: any[],
  colSpec: any[]
) {
  const cols: GridColDef[] = [];
  const colGroupingModel: GridColumnGroupingModel = [];
  const groupByColumns = getGroupByColumns(dsSchema);

  for (const key in row) {
    if (key === 'id') {
      continue;
    }

    const currentColSpec = colSpec.find((col) => col.name === key);

    let currentColType = '';

    if (currentColSpec) {
      currentColType = currentColSpec.data_type;
    }

    const col: GridColDef = {
      field: key,
      headerName: transformToLabel(key),
      flex: 100,
      minWidth: 100,
      align: typeof row[key] === 'number' ? 'right' : 'left',
      headerAlign: 'center',
      headerClassName: 'DataGrid-columnHeader',
      hideable: false,
      sortable: true,
      pinnable: false,
      filterable: true,
      renderCell: (params) => {
        // Format the value
        const cellValue = params.value;
        let formattedValue = cellValue;

        if (currentColType) {
          if (currentColType === 'float') {
            formattedValue = usNumberFormatter.format(cellValue);
          } else if (currentColType === 'timestamp') {
            const regexTestDate = /^\d{4}-\d{2}-\d{2}.00:00:00.*$/;
            if (regexTestDate.test(cellValue)) {
              formattedValue = cellValue.substring(0, 10);
            }
          }
        } else {
          const cellValueType = typeof cellValue;
          if (cellValueType === 'number') {
            formattedValue = usNumberFormatter.format(cellValue);
          }
          if (cellValueType === 'string') {
            const regexTestDate = /^\d{4}-\d{2}-\d{2}.00:00:00.*$/;
            if (regexTestDate.test(cellValue)) {
              formattedValue = cellValue.substring(0, 10);
            }
          }
        }

        // Highlight cells affected by vectors
        if (baseRows.length === 0) {
          return formattedValue;
        }

        const rowId =
          typeof params.id === 'string' ? parseInt(params.id) : params.id;

        const baseCellValue = baseRows[rowId][key];

        if (cellValue === baseCellValue) {
          return formattedValue;
        }

        return (
          <Tooltip
            disableFocusListener
            title={
              <span style={{ fontSize: '0.875rem' }}>
                Base value: {usNumberFormatter.format(baseCellValue)}
              </span>
            }
          >
            <div
              style={
                cellValue > baseCellValue
                  ? { color: 'rgb(46 161 46)' }
                  : { color: '#ff0000' }
              }
            >
              <span>{formattedValue}</span>
            </div>
          </Tooltip>
        );
      },
    };

    if (getSupportedPivotDisplayModes(dsSchema).length > 0) {
      if (groupByColumns?.includes(key)) {
        col.pinnable = true;
      } else {
        let colGroupName = '';
        let newColName = '';

        if (displayMode === DATA_DISPLAY_MODE_QUARTERLY) {
          const [year, quarter] = key.split('-');
          colGroupName = year as string;
          newColName = 'Q' + parseInt(quarter as string);
        } else if (displayMode === DATA_DISPLAY_MODE_MONTHLY) {
          const [year, month] = key.split('-');
          colGroupName = year as string;
          newColName = (
            monthNames[parseInt(month as string) - 1] as string
          )?.substring(0, 3);
        }

        if (colGroupName !== '') {
          const tempGroup = colGroupingModel.find(
            (group) => group.groupId === colGroupName
          );

          if (tempGroup) {
            tempGroup.children.push({ field: key });
          } else {
            colGroupingModel.push({
              groupId: colGroupName,
              headerName: transformToLabel(colGroupName),
              headerAlign: 'center',
              headerClassName: 'DataGrid-columnHeaderGroup',
              children: [{ field: key }],
            });
          }
        }

        if (newColName !== '') {
          col.headerName = newColName;
        }

        col.disableColumnMenu = true;
        col.sortable = false;
        col.filterable = false;
      }
    }

    cols.push(col);
  }

  return {
    cols,
    colGroupingModel,
  };
}

export function transformDataReport(rows: any[], reportName: string) {
  rows = JSON.parse(JSON.stringify(rows));

  if (reportName === 'financials') {
    return transformFinancials(rows);
  }
  if (reportName === 'crew_requirements') {
    return transformSeatRequirementsToYearlyFormat(rows);
  }
  if (reportName === 'movement_matrix') {
    return transformAssignmentMatrix(rows);
  }
  if (reportName === 'seniority_distribution') {
    return transformFTESummary(rows);
  }
  if (reportName === 'training') {
    return transformTraining(rows);
  }
  if (reportName === 'stats') {
    return transformStats(rows);
  }
  if (reportName === 'average_expense') {
    return transformAverageExpense(rows);
  }
  if (reportName === 'average_rate') {
    return transformAverageRate(rows);
  }
  if (reportName === 'kpis') {
    return transformKPIs(rows);
  }

  return rows;
}

const transformSeatRequirementsToYearlyFormat = (rows: any[]) => {
  const rowCount = rows.length;
  if (rowCount === 0) {
    return [];
  }

  const newRows: any[] = [];

  // Transform to pivot table
  let workingRow = rows[0];
  workingRow[workingRow['period']] = workingRow['num_fte'];

  let cursor = 1;
  while (cursor < rowCount) {
    const row = rows[cursor];
    if (row.hierarchy === workingRow.hierarchy) {
      workingRow[row['period']] = row['num_fte'];
    } else {
      newRows.push(workingRow);
      workingRow = {
        ...row,
      };
      workingRow[workingRow['period']] = workingRow['num_fte'];
    }

    cursor++;
  }
  newRows.push(workingRow);

  newRows.forEach((row, index) => {
    delete row['num_fte'];
    delete row['period'];
    row.id = index;
  });

  return newRows;
};

const transformAssignmentMatrix = (rows: any[]) => {
  const rowCount = rows.length;
  if (rowCount === 0) {
    return [];
  }

  const newRows: any[] = [];

  // Transform to pivot table
  let workingRow = rows[0];
  workingRow[workingRow['period']] = workingRow['status'];

  let cursor = 1;
  while (cursor < rowCount) {
    const row = rows[cursor];
    if (row.employee_id === workingRow.employee_id) {
      workingRow[row['period']] = row['status'];
    } else {
      newRows.push(workingRow);
      workingRow = {
        ...row,
      };
      workingRow[workingRow['period']] = workingRow['status'];
    }

    cursor++;
  }
  newRows.push(workingRow);

  newRows.forEach((row, index) => {
    row.id = index;
  });

  return newRows;
};

const transformFTESummary = (rows: any[]) => {
  const rowCount = rows.length;
  if (rowCount === 0) {
    return [];
  }

  const newRows: any[] = [];

  // Transform to pivot table
  let workingRow = rows[0];
  workingRow[workingRow['period']] = workingRow['sum_fte'];

  let cursor = 1;
  while (cursor < rowCount) {
    const row = rows[cursor];
    if (row.seniority === workingRow.seniority) {
      workingRow[row['period']] = row['sum_fte'];
    } else {
      newRows.push(workingRow);
      workingRow = {
        ...row,
      };
      workingRow[workingRow['period']] = workingRow['sum_fte'];
    }

    cursor++;
  }
  newRows.push(workingRow);

  newRows.forEach((row, index) => {
    row.id = index;
  });

  return newRows;
};

const transformFinancials = (rows: any[]) => {
  const rowCount = rows.length;

  if (rowCount === 0) {
    return [];
  }

  // Transform to pivot table
  const workingRow = rows[0];

  workingRow[workingRow['period']] = workingRow['value'];

  let newRows: any[] = rows.reduce((acc, row) => {
    const period = row['period'];
    const expenseGroupName = row['expense_group_name'];
    const expenseName = row['expense_name'];
    const crewType = row['crew_type'];

    const existingRow = acc.find(
      (a: any) =>
        a.expense_group_name === expenseGroupName &&
        a.expense_name === expenseName &&
        a.crew_type === crewType
    );

    if (existingRow) {
      existingRow[period] = row['value'];
    } else {
      row[period] = row['value'];
      acc.push(row);
    }

    return acc;
  }, []);

  newRows.forEach((row, index) => {
    row.id = index;
  });

  newRows = newRows.map((row) => {
    const crewType = row.crew_type;
    const expenseGroupName = row.expense_group_name;
    const expenseName = row.expense_name;

    if (
      crewType === 'all' &&
      expenseGroupName === 'all' &&
      expenseName === 'all'
    ) {
      return {
        ...row,
        tree: ['Total'],
      };
    } else if (crewType === 'all' && expenseName === 'all') {
      return {
        ...row,
        tree: [expenseGroupName],
      };
    } else if (crewType === 'all') {
      return {
        ...row,
        tree: [expenseGroupName, expenseName],
      };
    }

    return {
      ...row,
      tree: [expenseGroupName, expenseName, crewType],
    };
  });

  return newRows;
};

const transformAverageExpense = (rows: any[]) => {
  const rowCount = rows.length;

  if (rowCount === 0) {
    return [];
  }

  // Transform to pivot table
  const workingRow = rows[0];

  workingRow[workingRow['period']] = workingRow['value'];

  let newRows: any[] = rows.reduce((acc, row) => {
    const period = row['period'];
    const expenseName = row['expense_name'];
    const crewType = row['crew_type'];

    const existingRow = acc.find(
      (a: any) => a.expense_name === expenseName && a.crew_type === crewType
    );

    if (existingRow) {
      existingRow[period] = row['value'];
    } else {
      row[period] = row['value'];
      acc.push(row);
    }

    return acc;
  }, []);

  newRows.forEach((row, index) => {
    row.id = index;
  });

  newRows = newRows.map((row) => {
    const crewType = row.crew_type;
    const expenseName = row.expense_name;

    if (crewType === 'all') {
      return {
        ...row,
        tree: [expenseName],
      };
    }

    return {
      ...row,
      tree: [expenseName, crewType],
    };
  });

  return newRows;
};

const transformKPIs = (rows: any[]) => {
  const rowCount = rows.length;

  if (rowCount === 0) {
    return [];
  }

  // Transform to pivot table
  const workingRow = rows[0];

  workingRow[workingRow['period']] = workingRow['value'];

  let newRows: any[] = rows.reduce((acc, row) => {
    const period = row['period'];
    const statsName = row['stats_name'];
    const crewType = row['crew_type'];

    const existingRow = acc.find(
      (a: any) => a.stats_name === statsName && a.crew_type === crewType
    );

    if (existingRow) {
      existingRow[period] = row['value'];
    } else {
      row[period] = row['value'];
      acc.push(row);
    }

    return acc;
  }, []);

  newRows.forEach((row, index) => {
    row.id = index;
  });

  newRows = newRows.map((row) => {
    const crewType = row.crew_type;
    const statsName = row.stats_name;

    if (crewType === 'all') {
      return {
        ...row,
        tree: [statsName],
      };
    }
    return {
      ...row,
      tree: [statsName, crewType],
    };
  });

  return newRows;
};

const transformStats = (rows: any[]) => {
  const rowCount = rows.length;

  if (rowCount === 0) {
    return [];
  }

  const result = rows.reduce((acc, row, index) => {
    const period = row['period'];
    const statsName = row['stats_name'];

    const existingRow = acc.find((a: any) => a.stats_name === statsName);

    if (existingRow) {
      existingRow[period] = row['value'];
    } else {
      row[period] = row['value'];
      acc.push({
        id: index,
        ...row,
      });
    }

    return acc;
  }, []);

  return result;
};

const transformAverageRate = (rows: any[]) => {
  const rowCount = rows.length;

  if (rowCount === 0) {
    return [];
  }

  const result = rows.reduce((acc, row, index) => {
    const period = row['period'];
    const crewType = row['crew_type'];

    const existingRow = acc.find((a: any) => a['crew_type'] === crewType);

    if (existingRow) {
      existingRow[period] = row['rate'];
    } else {
      row[period] = row['rate'];
      acc.push({
        id: index,
        ...row,
      });
    }

    return acc;
  }, []);

  return result;
};

const transformTraining = (rows: any[]) => {
  const rowCount = rows.length;

  if (rowCount === 0) {
    return [];
  }

  const newRows: any[] = [];

  let workingRow = rows[0];
  workingRow[workingRow['period']] = workingRow['count'];

  let cursor = 1;

  while (cursor < rowCount) {
    const row = rows[cursor];

    if (row.from === workingRow.from && row.to === workingRow.to) {
      workingRow[row['period']] = row['count'] ?? 0;
    } else {
      newRows.push(workingRow);
      workingRow = {
        ...row,
      };
      workingRow[workingRow['period']] = workingRow['count'] ?? 0;
    }

    cursor++;
  }

  newRows.push(workingRow);

  newRows.forEach((row, index) => {
    row.id = index;
  });

  newRows.push(
    newRows.reduce(
      (acc, row) => {
        for (const key in row) {
          if (key === 'from' || key === 'to') {
            continue;
          }

          if (acc[key] === undefined) {
            acc[key] = 0;
          }

          acc[key] += row[key];
        }

        return acc;
      },
      {
        from: 'Total',
        to: '',
      }
    )
  );

  return newRows;
};

export function getColsFromRowReport(
  row: any,
  reportName: string,
  baseRows: any[] = []
) {
  if (reportName === 'crew_requirements') {
    return getColsCrewRequirements(row, baseRows);
  }
  if (reportName === 'movement_matrix') {
    return getColsAssignmentMatrix(row);
  }
  if (reportName === 'seniority_distribution') {
    return getColsFTESummary(row, baseRows);
  }
  if (reportName === 'financials') {
    return getColsFinancials(row, baseRows);
  }
  if (reportName === 'training') {
    return getColsTraining(row, baseRows);
  }
  if (reportName === 'stats') {
    return getColsStats(row, baseRows);
  }
  if (reportName === 'average_expense') {
    return getColsAverageExpense(row, baseRows);
  }
  if (reportName === 'average_rate') {
    return getColsAverageRate(row, baseRows);
  }
  if (reportName === 'kpis') {
    return getColsKPIs(row, baseRows);
  }
  return [];
}

export function getFieldValues(rows: any[], field: string) {
  rows = JSON.parse(JSON.stringify(rows));

  const results = new Set<any>();
  rows.forEach((row) => {
    results.add(row[field]);
  });

  return Array.from(results);
}

export function getFilterableColumns(dsSchema: TypeDataTableSchema) {
  return dsSchema?.filterable_columns ?? [];
}

const getColsCrewRequirements = (row: any, baseRows: any[]) => {
  const cols: GridColDef[] = [];

  cols.push({
    field: 'hierarchy',
    headerName: 'Hierarchy',
    flex: 100,
    minWidth: 100,
    align: 'right',
    headerAlign: 'center',
    headerClassName: 'DataGrid-columnHeader',
    hideable: false,
    sortable: true,
    pinnable: true,
    filterable: true,
  });
  cols.push({
    field: 'seat',
    headerName: 'Seat',
    flex: 100,
    minWidth: 100,
    align: 'left',
    headerAlign: 'center',
    headerClassName: 'DataGrid-columnHeader',
    hideable: false,
    sortable: true,
    pinnable: true,
    filterable: true,
  });

  for (const key in row) {
    if (
      yearlyPeriodList.includes(key) ||
      monthlyPeriodList.includes(key) ||
      weeklyPeriodList.includes(key)
    ) {
      cols.push({
        field: key,
        headerName: transformToLabel(key),
        flex: 100,
        minWidth: 100,
        align: 'right',
        headerAlign: 'center',
        headerClassName: 'DataGrid-columnHeader',
        hideable: false,
        sortable: false,
        pinnable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const cellValue = params.value;

          const formattedValue =
            seatRequirementsNumberFormatter.format(cellValue);

          if (baseRows.length === 0) {
            return formattedValue;
          }

          const rowId =
            typeof params.id === 'string' ? parseInt(params.id) : params.id;

          const baseCellValue = baseRows[rowId][key];

          if (cellValue === baseCellValue) {
            return '-';
          }
          if (cellValue < baseCellValue) {
            const newCellValue = seatRequirementsNumberFormatter.format(
              baseCellValue - cellValue
            );

            return (
              <ModelCompareTooltip numberA={baseCellValue} numberB={cellValue}>
                <span style={{ color: 'rgb(46 161 46)' }}>{newCellValue}</span>
              </ModelCompareTooltip>
            );
          }
          if (cellValue > baseCellValue) {
            const newCellValue = seatRequirementsNumberFormatter.format(
              cellValue - baseCellValue
            );

            return (
              <ModelCompareTooltip numberA={baseCellValue} numberB={cellValue}>
                <span style={{ color: '#ff0000' }}>-{newCellValue}</span>
              </ModelCompareTooltip>
            );
          }

          return (
            <ModelCompareTooltip numberA={baseCellValue} numberB={cellValue}>
              <span>{formattedValue}</span>
            </ModelCompareTooltip>
          );
        },
      });
    }
  }

  return cols;
};

const getColsAssignmentMatrix = (row: any) => {
  const cols: GridColDef[] = [];

  cols.push({
    field: 'employee_id',
    headerName: 'Employee ID',
    flex: 100,
    minWidth: 100,
    align: 'left',
    headerAlign: 'center',
    headerClassName: 'DataGrid-columnHeader',
    hideable: false,
    sortable: true,
    pinnable: true,
    filterable: true,
    renderCell: (params) => {
      if (params.row.special_pref === 'Special') {
        return params.row.employee_id + ' (Special)';
      }
      if (params.row.is_future_employee === true) {
        return 'Future hire ' + params.row.seniority_number;
      }
      return params.formattedValue;
    },
  });

  for (const key in row) {
    if (
      yearlyPeriodList.includes(key) ||
      monthlyPeriodList.includes(key) ||
      weeklyPeriodList.includes(key)
    ) {
      cols.push({
        field: key,
        headerName: transformToLabel(key),
        flex: 100,
        minWidth: 100,
        align: 'left',
        headerAlign: 'center',
        headerClassName: 'DataGrid-columnHeader',
        hideable: false,
        sortable: false,
        pinnable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => params.value,
      });
    }
  }

  return cols;
};

const getColsFTESummary = (row: any, baseRows: any[]) => {
  const cols: GridColDef[] = [];

  cols.push({
    field: 'step',
    headerName: 'Step',
    flex: 100,
    minWidth: 100,
    align: 'right',
    headerAlign: 'center',
    headerClassName: 'DataGrid-columnHeader',
    hideable: false,
    sortable: true,
    pinnable: true,
    filterable: true,
  });
  cols.push({
    field: 'seniority',
    headerName: 'Seniority',
    flex: 100,
    minWidth: 100,
    align: 'left',
    headerAlign: 'center',
    headerClassName: 'DataGrid-columnHeader',
    hideable: false,
    sortable: true,
    pinnable: true,
    filterable: true,
  });

  for (const key in row) {
    if (
      yearlyPeriodList.includes(key) ||
      monthlyPeriodList.includes(key) ||
      weeklyPeriodList.includes(key)
    ) {
      cols.push({
        field: key,
        headerName: transformToLabel(key),
        flex: 100,
        minWidth: 100,
        align: 'right',
        headerAlign: 'center',
        headerClassName: 'DataGrid-columnHeader',
        hideable: false,
        sortable: false,
        pinnable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          // Format the value
          const cellValue = params.value;
          const formattedValue = usNumberFormatter.format(cellValue);

          if (baseRows.length === 0 || isNaN(Number(params.id))) {
            return formattedValue;
          }

          const rowId =
            typeof params.id === 'string' ? parseInt(params.id) : params.id;

          const baseCellValue = baseRows[rowId][key];

          if (cellValue === baseCellValue) {
            return '-';
          }
          if (cellValue < baseCellValue) {
            const newCellValue = seatRequirementsNumberFormatter.format(
              baseCellValue - cellValue
            );

            return (
              <ModelCompareTooltip numberA={baseCellValue} numberB={cellValue}>
                <span style={{ color: 'rgb(46 161 46)' }}>{newCellValue}</span>
              </ModelCompareTooltip>
            );
          }
          if (cellValue > baseCellValue) {
            const newCellValue = seatRequirementsNumberFormatter.format(
              cellValue - baseCellValue
            );

            return (
              <ModelCompareTooltip numberA={baseCellValue} numberB={cellValue}>
                <span style={{ color: '#ff0000' }}>-{newCellValue}</span>
              </ModelCompareTooltip>
            );
          }

          return (
            <ModelCompareTooltip numberA={baseCellValue} numberB={cellValue}>
              <span>{formattedValue}</span>
            </ModelCompareTooltip>
          );
        },
      });
    }
  }

  return cols;
};

const getColsFinancials = (row: any, baseRows: any[]) => {
  const cols: GridColDef[] = [];

  for (const key in row) {
    if (
      yearlyPeriodList.includes(key) ||
      monthlyPeriodList.includes(key) ||
      weeklyPeriodList.includes(key)
    ) {
      cols.push({
        field: key,
        headerName: transformToLabel(key),
        flex: 100,
        minWidth: 100,
        align: 'right',
        headerAlign: 'center',
        headerClassName: 'DataGrid-columnHeader',
        hideable: false,
        sortable: false,
        pinnable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const cellValue = params.value;
          let newCellValue: any = financialsFormatter.format(cellValue);

          if (baseRows.length > 0 && !isNaN(Number(params.id))) {
            const rowId =
              typeof params.id === 'string' ? parseInt(params.id) : params.id;

            const baseCellValue = baseRows[rowId][key];

            if (cellValue === baseCellValue) {
              newCellValue = '-';
            } else if (cellValue < baseCellValue) {
              const delta = financialsFormatter.format(
                baseCellValue - cellValue
              );

              newCellValue = (
                <ModelCompareTooltip
                  numberA={baseCellValue}
                  numberB={cellValue}
                >
                  <span style={{ color: 'rgb(46 161 46)' }}>{delta}</span>
                </ModelCompareTooltip>
              );
            } else if (cellValue > baseCellValue) {
              const delta = financialsFormatter.format(
                cellValue - baseCellValue
              );

              newCellValue = (
                <ModelCompareTooltip
                  numberA={baseCellValue}
                  numberB={cellValue}
                >
                  <span style={{ color: '#ff0000' }}>-{delta}</span>
                </ModelCompareTooltip>
              );
            }
          }

          if (
            params.row.expense_type_verbose !== 'Total CA' &&
            params.row.expense_type_verbose !== 'Total FO'
          ) {
            newCellValue = <strong>{newCellValue}</strong>;
          }

          return newCellValue;
        },
      });
    }
  }

  return cols;
};

const getColsAverageExpense = (row: any, baseRows: any[]) => {
  const cols: GridColDef[] = [];

  for (const key in row) {
    if (
      yearlyPeriodList.includes(key) ||
      monthlyPeriodList.includes(key) ||
      weeklyPeriodList.includes(key)
    ) {
      cols.push({
        field: key,
        headerName: transformToLabel(key),
        flex: 100,
        minWidth: 100,
        align: 'right',
        headerAlign: 'center',
        headerClassName: 'DataGrid-columnHeader',
        hideable: false,
        sortable: false,
        pinnable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const cellValue = params.value;
          let newCellValue: any = usNumberFormatter.format(cellValue);

          if (baseRows.length > 0 && !isNaN(Number(params.id))) {
            const rowId =
              typeof params.id === 'string' ? parseInt(params.id) : params.id;

            const baseCellValue = baseRows[rowId][key];

            if (cellValue === baseCellValue) {
              newCellValue = '-';
            } else if (cellValue < baseCellValue) {
              const delta = usNumberFormatter.format(baseCellValue - cellValue);

              newCellValue = (
                <ModelCompareTooltip
                  numberA={baseCellValue}
                  numberB={cellValue}
                >
                  <span style={{ color: 'rgb(46 161 46)' }}>{delta}</span>
                </ModelCompareTooltip>
              );
            } else if (cellValue > baseCellValue) {
              const delta = usNumberFormatter.format(cellValue - baseCellValue);

              newCellValue = (
                <ModelCompareTooltip
                  numberA={baseCellValue}
                  numberB={cellValue}
                >
                  <span style={{ color: '#ff0000' }}>-{delta}</span>
                </ModelCompareTooltip>
              );
            }
          }

          if (
            params.row.expense_type_verbose !== 'Total CA' &&
            params.row.expense_type_verbose !== 'Total FO'
          ) {
            newCellValue = <strong>{newCellValue}</strong>;
          }

          return newCellValue;
        },
      });
    }
  }

  return cols;
};

const getColsTraining = (row: any, baseRows: any[]) => {
  const cols: GridColDef[] = [];

  for (const key in row) {
    if (
      yearlyPeriodList.includes(key) ||
      monthlyPeriodList.includes(key) ||
      weeklyPeriodList.includes(key) ||
      key === 'from' ||
      key === 'to'
    ) {
      cols.push({
        field: key,
        headerName: transformToLabel(key),
        flex: 100,
        minWidth: 100,
        align: 'right',
        headerAlign: 'center',
        headerClassName: 'DataGrid-columnHeader',
        hideable: false,
        sortable: false,
        pinnable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const cellValue = params.value;
          let newCellValue: any = cellValue ?? 0;

          if (baseRows.length > 0 && !isNaN(Number(params.id))) {
            const rowId =
              typeof params.id === 'string' ? parseInt(params.id) : params.id;

            const baseCellValue = baseRows[rowId][key];

            if (cellValue === baseCellValue) {
              newCellValue = '-';
            } else if (cellValue < baseCellValue) {
              const delta = financialsFormatter.format(
                baseCellValue - cellValue
              );

              newCellValue = (
                <ModelCompareTooltip
                  numberA={baseCellValue}
                  numberB={cellValue}
                >
                  <span style={{ color: 'rgb(46 161 46)' }}>{delta}</span>
                </ModelCompareTooltip>
              );
            } else if (cellValue > baseCellValue) {
              const delta = financialsFormatter.format(
                cellValue - baseCellValue
              );

              newCellValue = (
                <ModelCompareTooltip
                  numberA={baseCellValue}
                  numberB={cellValue}
                >
                  <span style={{ color: '#ff0000' }}>-{delta}</span>
                </ModelCompareTooltip>
              );
            }
          }

          return newCellValue;
        },
      });
    }
  }

  return cols;
};

const getColsStats = (row: any, baseRows: any[]) => {
  const cols: GridColDef[] = [];

  for (const key in row) {
    if (
      yearlyPeriodList.includes(key) ||
      monthlyPeriodList.includes(key) ||
      weeklyPeriodList.includes(key) ||
      key === 'stats_name'
    ) {
      cols.push({
        field: key,
        headerName: transformToLabel(key),
        flex: 100,
        minWidth: 100,
        align: 'right',
        headerAlign: 'center',
        headerClassName: 'DataGrid-columnHeader',
        hideable: false,
        sortable: false,
        pinnable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const cellValue = params.value;
          let newCellValue: any = cellValue ?? 0;

          if (baseRows.length > 0 && !isNaN(Number(params.id))) {
            const rowId =
              typeof params.id === 'string' ? parseInt(params.id) : params.id;

            const baseCellValue = baseRows[rowId][key];

            if (cellValue === baseCellValue) {
              newCellValue = '-';
            } else if (cellValue < baseCellValue) {
              const delta = financialsFormatter.format(
                baseCellValue - cellValue
              );

              newCellValue = (
                <ModelCompareTooltip
                  numberA={baseCellValue}
                  numberB={cellValue}
                >
                  <span style={{ color: 'rgb(46 161 46)' }}>{delta}</span>
                </ModelCompareTooltip>
              );
            } else if (cellValue > baseCellValue) {
              const delta = financialsFormatter.format(
                cellValue - baseCellValue
              );

              newCellValue = (
                <ModelCompareTooltip
                  numberA={baseCellValue}
                  numberB={cellValue}
                >
                  <span style={{ color: '#ff0000' }}>-{delta}</span>
                </ModelCompareTooltip>
              );
            }
          }

          return newCellValue;
        },
      });
    }
  }

  return cols;
};

const getColsKPIs = (row: any, baseRows: any[]) => {
  const cols: GridColDef[] = [];

  for (const key in row) {
    if (
      yearlyPeriodList.includes(key) ||
      monthlyPeriodList.includes(key) ||
      weeklyPeriodList.includes(key)
    ) {
      cols.push({
        field: key,
        headerName: transformToLabel(key),
        flex: 100,
        minWidth: 100,
        align: 'right',
        headerAlign: 'center',
        headerClassName: 'DataGrid-columnHeader',
        hideable: false,
        sortable: false,
        pinnable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const cellValue = params.value;
          let newCellValue: any = usNumberFormatter.format(cellValue);

          if (baseRows.length > 0 && !isNaN(Number(params.id))) {
            const rowId =
              typeof params.id === 'string' ? parseInt(params.id) : params.id;

            const baseCellValue = baseRows[rowId][key];

            if (cellValue === baseCellValue) {
              newCellValue = '-';
            } else if (cellValue < baseCellValue) {
              const delta = usNumberFormatter.format(baseCellValue - cellValue);

              newCellValue = (
                <ModelCompareTooltip
                  numberA={baseCellValue}
                  numberB={cellValue}
                >
                  <span style={{ color: 'rgb(46 161 46)' }}>{delta}</span>
                </ModelCompareTooltip>
              );
            } else if (cellValue > baseCellValue) {
              const delta = usNumberFormatter.format(cellValue - baseCellValue);

              newCellValue = (
                <ModelCompareTooltip
                  numberA={baseCellValue}
                  numberB={cellValue}
                >
                  <span style={{ color: '#ff0000' }}>-{delta}</span>
                </ModelCompareTooltip>
              );
            }
          }

          if (
            params.row.expense_type_verbose !== 'Total CA' &&
            params.row.expense_type_verbose !== 'Total FO'
          ) {
            newCellValue = <strong>{newCellValue}</strong>;
          }

          return newCellValue;
        },
      });
    }
  }

  return cols;
};

const getColsAverageRate = (row: any, baseRows: any[]) => {
  const cols: GridColDef[] = [];

  for (const key in row) {
    if (
      yearlyPeriodList.includes(key) ||
      monthlyPeriodList.includes(key) ||
      key === 'crew_type'
    ) {
      cols.push({
        field: key,
        headerName:
          key === 'crew_type' ? 'Average Rate' : transformToLabel(key),
        flex: 100,
        minWidth: 100,
        align: 'right',
        headerAlign: 'center',
        headerClassName: 'DataGrid-columnHeader',
        hideable: false,
        sortable: false,
        pinnable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const cellValue = params.value;
          let newCellValue: any = cellValue ?? 0;

          if (baseRows.length > 0 && !isNaN(Number(params.id))) {
            const rowId =
              typeof params.id === 'string' ? parseInt(params.id) : params.id;

            const baseCellValue = baseRows[rowId][key];

            if (cellValue === baseCellValue) {
              newCellValue = '-';
            } else if (cellValue < baseCellValue) {
              const delta = financialsFormatter.format(
                baseCellValue - cellValue
              );

              newCellValue = (
                <ModelCompareTooltip
                  numberA={baseCellValue}
                  numberB={cellValue}
                >
                  <span style={{ color: 'rgb(46 161 46)' }}>{delta}</span>
                </ModelCompareTooltip>
              );
            } else if (cellValue > baseCellValue) {
              const delta = financialsFormatter.format(
                cellValue - baseCellValue
              );

              newCellValue = (
                <ModelCompareTooltip
                  numberA={baseCellValue}
                  numberB={cellValue}
                >
                  <span style={{ color: '#ff0000' }}>-{delta}</span>
                </ModelCompareTooltip>
              );
            }
          }

          return newCellValue;
        },
      });
    }
  }

  return cols;
};

export const usNumberFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const seatRequirementsNumberFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
});

export const financialsFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});
