import { type Role } from '../types';
import { mainApi } from '../utils/api';

export type DeleteProjectMemberInput = {
  projectId: number;
  orgId: number;
  memberId: number;
};

export type UpdateProjectMemberInput = {
  projectId: number;
  orgId: number;
  memberId: number;
  data: {
    role: Role;
    date_joined?: string;
  };
};

export type GetProjectByIdInput = {
  projectId: number;
  orgId: number;
};

export type UpdateProjectInput = {
  projectId: number;
  orgId: number;
  data: {
    name?: string;
    description?: string;
  };
};

export type DeleteProjectInput = {
  projectId: number;
  orgId: number;
};

export type CreateProjectInput = {
  orgId: number;
  data: {
    name: string;
    organization: number;
    description?: string;
  };
};

export type GetProjectMembersInput = {
  projectId: number;
  orgId: number;
};

export type GetProjectMemberByIdInput = {
  projectId: number;
  orgId: number;
  memberId: number;
};

export type GetProjectSharedModelsInput = {
  projectId: number;
  orgId: number;
};

export type GetProjectsInput = {
  orgId: number;
};

export type GetProjectSharedScenariosInput = {
  projectId: number;
  orgId: number;
};

export type CompleteCreateProjectInput = {
  projectId: number;
  orgId: number;
};

export type GetProjectOutputSchemaInput = {
  projectId: number;
  orgId: number;
};

const getProjectMembers = ({ orgId, projectId }: GetProjectMembersInput) =>
  mainApi.get(`organizations/${orgId}/projects/${projectId}/members/`);

const deleteProjectMember = ({
  orgId,
  projectId,
  memberId,
}: DeleteProjectMemberInput) =>
  mainApi.delete(
    `organizations/${orgId}/projects/${projectId}/members/${memberId}/`
  );

const updateProjectMember = ({
  projectId,
  memberId,
  orgId,
  data,
}: UpdateProjectMemberInput) =>
  mainApi.put(
    `organizations/${orgId}/projects/${projectId}/members/${memberId}/`,
    data
  );

const getProjectMemberById = ({
  orgId,
  projectId,
  memberId,
}: GetProjectMemberByIdInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/members/${memberId}/`
  );

const getProjectById = ({ projectId, orgId }: GetProjectByIdInput) =>
  mainApi.get(`organizations/${orgId}/projects/${projectId}/`);

const updateProject = ({ projectId, orgId, data }: UpdateProjectInput) =>
  mainApi.put(`organizations/${orgId}/projects/${projectId}/`, data);

const deleteProject = ({ projectId, orgId }: DeleteProjectInput) =>
  mainApi.delete(`organizations/${orgId}/projects/${projectId}/`);

const getProjects = ({ orgId }: GetProjectsInput) =>
  mainApi.get(`organizations/${orgId}/projects/`);

const createProject = ({ orgId, data }: CreateProjectInput) =>
  mainApi.post(`organizations/${orgId}/projects/`, data);

const getProjectSharedModels = ({
  orgId,
  projectId,
}: GetProjectSharedModelsInput) =>
  mainApi.get(`organizations/${orgId}/projects/${projectId}/shared_models/`);

const getProjectSharedScenarios = ({
  orgId,
  projectId,
}: GetProjectSharedScenariosInput) =>
  mainApi.get(`organizations/${orgId}/projects/${projectId}/shared_scenarios/`);

const completeOnboarding = ({ orgId, projectId }: CompleteCreateProjectInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/complete_onboarding/`
  );

const getProjectOutputSchema = ({
  orgId,
  projectId,
}: GetProjectOutputSchemaInput) =>
  mainApi.get(`organizations/${orgId}/projects/${projectId}/output_schema/`);

export {
  createProject,
  completeOnboarding,
  getProjects,
  getProjectById,
  getProjectMembers,
  getProjectMemberById,
  getProjectSharedModels,
  getProjectSharedScenarios,
  getProjectOutputSchema,
  deleteProject,
  updateProjectMember,
  deleteProjectMember,
  updateProject,
};
