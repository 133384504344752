import React, { useMemo, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Collapse } from '@mui/material';

import { type TypePredefinedExpense } from '../../common/dataTypes';
import { toastPromise } from '../../common/utils';
import { useUpdateModel } from '../../hooks';
import { useGetPreDefinedExpenses } from '../../hooks/useExpenseHook';

import AddExpenseFormDialog, {
  type AddExpenseFormSchemaType,
} from './AddExpenseFormDialog';
import CheckboxesCollapse from './CheckboxesCollapse';

export const PredefinedExpensesForm = () => {
  const [open, setOpen] = useState(true);

  const { org_id } = useParams();

  const orgId = Number(org_id);

  const { data: predefinedExpenseSets } = useGetPreDefinedExpenses(
    {
      orgId,
    },
    {
      enabled: !!orgId,
    }
  );

  const defaultExpenseSets = predefinedExpenseSets?.reduce((acc, cur) => {
    const groupOrder = cur.group.ifs_order;

    acc[groupOrder] = [...(acc[groupOrder] ?? []), cur];

    acc[groupOrder] = (acc[groupOrder] as TypePredefinedExpense[]).sort(
      (a, b) => a.ifs_order - b.ifs_order
    );

    return acc;
  }, [] as TypePredefinedExpense[][]);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div className="rounded-[4px] border border-[#E4E7EC] bg-[#FFF] p-6">
      <button
        type="button"
        onClick={handleToggle}
        className="flex items-center gap-1 text-[#666]"
      >
        {open ? <ArrowDropUp /> : <ArrowDropDown />}
        <label className="text-lg font-semibold capitalize">
          Predefined Expenses
        </label>
      </button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className="py-2">
          <>
            {defaultExpenseSets &&
              Array.from(Array(defaultExpenseSets.length - 1).keys()).map(
                (_, index) => {
                  const items = defaultExpenseSets[index + 1];

                  return (
                    <CheckboxesCollapse
                      name={items[0].group.name}
                      title={items[0].group.name}
                      items={items.map((e) => ({
                        label: e.name,
                        value: e.id.toString(),
                      }))}
                    />
                  );
                }
              )}
          </>
        </div>
      </Collapse>
    </div>
  );
};

export const UserDefinedExpensesForm = () => {
  const [open, setOpen] = useState(true);
  const [openCreateExpenseDialog, setOpenCreateExpenseDialog] = useState(false);

  const { watch, setValue } = useFormContext();

  const defaultData = watch('default');

  const userData = watch('user');

  const handleToggle = () => {
    setOpen(!open);
  };

  const categories = useMemo(
    () =>
      Object.keys(defaultData ?? {}).reduce(
        (acc, cur) => {
          acc = [
            ...acc,
            {
              label: cur,
              value: cur,
            },
          ];
          return acc;
        },
        [] as { label: string; value: string }[]
      ),
    [defaultData]
  );

  const handleOpenCreateExpenseDialog = () => {
    setOpenCreateExpenseDialog(true);
  };

  const handleCloseCreateExpenseDialog = () => {
    setOpenCreateExpenseDialog(false);
  };

  const handleAddExpense = (input: AddExpenseFormSchemaType) => {
    const { category, name } = input;

    setValue(`user.${category}.${name}`, true);
  };

  return (
    <div className="my-6 rounded-[4px] border border-[#E4E7EC] bg-[#FFF] p-6">
      <div className="flex justify-between">
        <button
          type="button"
          onClick={handleToggle}
          className="flex items-center gap-1 text-[#666]"
        >
          {open ? <ArrowDropUp /> : <ArrowDropDown />}
          <label className="text-lg font-semibold capitalize">
            User-Defined Expenses
          </label>
        </button>
        {categories && (
          <AddExpenseFormDialog
            title="Add User-Defined Expense"
            categories={categories}
            onSubmit={handleAddExpense}
            open={openCreateExpenseDialog}
            handleOpen={handleOpenCreateExpenseDialog}
            handleClose={handleCloseCreateExpenseDialog}
          />
        )}
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {Object.keys(userData ?? {}).map((category) => (
          <CheckboxesCollapse
            key={category}
            name={category}
            title={category}
            group="user"
            items={Object.keys(userData[category]).map((name) => ({
              label: name,
              value: name,
            }))}
          />
        ))}
      </Collapse>
    </div>
  );
};

const CreateExpenseForm = () => {
  const form = useForm();

  const { handleSubmit, watch } = form;
  const { org_id, project_id, model_id } = useParams();

  const orgId = Number(org_id);
  const projectId = Number(project_id);
  const modelId = Number(model_id);

  const { mutateAsync: updateModel, isPending: isUpdatingModel } =
    useUpdateModel();

  const allDefault = Object.values(watch('default') ?? {})
    .map((e: any) => Object.keys(e).filter((key) => e[key]))
    .flat();

  const onSubmit = (data: any) => {
    if (allDefault.length === 0 || !orgId || !projectId || !modelId) {
      return;
    }

    const res = updateModel({
      orgId,
      projectId,
      modelId,
      data: {
        predefined_expenses: allDefault.map(Number),
      },
    });

    toastPromise({
      promise: res,
      content: 'Expense set updated successfully',
    });
  };

  return (
    <div className="flex flex-col pb-12">
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-white sticky top-[60px] z-50 flex justify-between overflow-hidden py-3">
            <h1 className="text-[28px] font-bold">
              Select Predefined Expenses and User-Defined Expenses
            </h1>
            <LoadingButton
              loading={isUpdatingModel}
              type="submit"
              variant="contained"
            >
              Save
            </LoadingButton>
          </div>
          <div>
            <PredefinedExpensesForm />
            <UserDefinedExpensesForm />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateExpenseForm;
