import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { ArrowUpward } from '@mui/icons-material';
import { Button, MenuItem } from '@mui/material';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  Archive,
  Copy,
  EllipsisVertical,
  Pencil,
  Plus,
  Trash2,
} from 'lucide-react';

import { useGetModelById, useGetModels } from '../../../hooks/useModelsHook';
import cn from '../../../utils/cn';
import ConfirmDeleteDialog from '../../ConfirmDeleteDialog';
import MenuDropdown from '../../MenuDropdown';
import CreateModelFormDialog from '../../model/CreateModelFormDialog';
import InviteFormDialog from '../../organizations/InviteFormDialog';
import SearchInput from '../../SearchInput';
import Spinner from '../../Spinner';

export type DataRow = {
  id: number;
  name: string;
  description: string;
  dataset: string;
  lastModified: string;
  createdBy: string;
};

const columnHelper = createColumnHelper<DataRow>();

const columns = ({ handleSelect }: { handleSelect: (id: number) => void }) => [
  columnHelper.accessor('name', {
    cell: (info) => {
      const id = info.row.original.id;

      return (
        <div
          onClick={() => handleSelect(id)}
          className="group flex items-center gap-2 py-3 text-start"
        >
          <p className="w-full">{info.getValue()}</p>
        </div>
      );
    },
    header: () => (
      <div className="flex items-start gap-2">
        <p>Model</p>
        <ArrowUpward
          style={{
            height: '20px',
            width: '20px',
          }}
        />
      </div>
    ),
  }),
  columnHelper.accessor('description', {
    id: 'description',
    cell: (info) => {
      const id = info.row.original.id;

      const handleOnclick = () => {
        handleSelect(id);
      };

      return info.getValue() ? (
        <div onClick={handleOnclick} className="w-full">
          {info.getValue()}
        </div>
      ) : (
        <div
          onClick={handleOnclick}
          className="text-gray-400 w-full text-start"
        >
          --
        </div>
      );
    },
    header: () => <div>Description</div>,
  }),
  columnHelper.accessor('dataset', {
    id: 'dataset',
    cell: (info) => {
      const id = info.row.original.id;

      const handleOnclick = () => {
        handleSelect(id);
      };

      return info.getValue() ? (
        <div onClick={handleOnclick} className="w-full">
          {info.getValue()}
        </div>
      ) : (
        <div
          onClick={handleOnclick}
          className="text-gray-400 w-full text-center"
        >
          --
        </div>
      );
    },
    header: () => <div>Dataset</div>,
  }),
  columnHelper.accessor('lastModified', {
    id: 'lastModified',
    cell: (info) => {
      const id = info.row.original.id;

      const handleOnclick = () => {
        handleSelect(id);
      };

      return info.getValue() ? (
        <div onClick={handleOnclick} className="w-full">
          {info.getValue()}
        </div>
      ) : (
        <div
          onClick={handleOnclick}
          className="text-gray-400 w-full text-center"
        >
          --
        </div>
      );
    },
    header: () => <div>Last Modified</div>,
  }),
  columnHelper.accessor('createdBy', {
    id: 'createdBy',
    cell: (info) => {
      const id = info.row.original.id;

      const handleOnclick = () => {
        handleSelect(id);
      };

      const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

      const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] =
        useState(false);

      const [openCreateModelDialog, setOpenCreateModelDialog] = useState(false);

      const [openInviteFormDialog, setOpenInviteFormDialog] = useState(false);

      const { org_id, project_id } = useParams();

      const open = Boolean(anchorEl);

      const orgId = Number(org_id);
      const projectId = Number(project_id);

      const { data: model } = useGetModelById(
        {
          projectId,
          orgId,
          modelId: id,
        },
        {
          enabled: !!id && !!projectId && !!orgId,
        }
      );

      const handleCloseDropdown = () => setAnchorEl(null);

      const handleOpenConfirmDeleteDialog = () => {
        setOpenConfirmDeleteDialog(true);
      };

      const handleCloseConfirmDeleteDialog = () => {
        setOpenConfirmDeleteDialog(false);
      };

      const handleOpenCreateModelDialog = () => {
        setOpenCreateModelDialog(true);
      };

      const handleCloseCreateModelDialog = () => {
        setOpenCreateModelDialog(false);
      };

      const handleOpenInviteFormDialog = () => {
        setOpenInviteFormDialog(true);
      };

      const handleCloseInviteFormDialog = () => {
        setOpenInviteFormDialog(false);
      };

      return info.getValue() ? (
        <div className="flex min-w-[200px] justify-between">
          <p className="max-w-[180px] grow truncate" onClick={handleOnclick}>
            {info.getValue()}
          </p>
          <MenuDropdown
            trigger={
              <button>
                <EllipsisVertical width={20} height={20} />
              </button>
            }
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            style={{
              marginTop: '5px',
            }}
            open={open}
          >
            <MenuItem
              onClick={() => {
                handleCloseDropdown();
                handleOpenInviteFormDialog();
              }}
            >
              <div className="flex items-center space-x-2">
                <Copy size={15} />
                <p>Invite members</p>
              </div>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleCloseDropdown();
                handleOpenCreateModelDialog();
              }}
            >
              <div className="flex items-center space-x-2">
                <Pencil size={15} />
                <p>Edit</p>
              </div>
            </MenuItem>
            <MenuItem>
              <div className="flex items-center space-x-2">
                <Archive size={15} />
                <p>Archive</p>
              </div>
            </MenuItem>
            <div className="!border-t !border-[#E4E7EC]" />
            <MenuItem
              onClick={() => {
                handleCloseDropdown();
                handleOpenConfirmDeleteDialog();
              }}
            >
              <div className="flex items-center space-x-2 text-primary-red">
                <Trash2 size={15} />
                <p>Delete</p>
              </div>
            </MenuItem>
          </MenuDropdown>
          {model && (
            <CreateModelFormDialog
              open={openCreateModelDialog}
              title={'Edit model'}
              handleOpenDialog={handleOpenCreateModelDialog}
              handleCloseDialog={handleCloseCreateModelDialog}
              defaultValues={{
                name: model.name,
                description: model.description,
                //....dataset and scenario
              }}
            />
          )}
          {model && (
            <ConfirmDeleteDialog
              type="dataset"
              row={{
                id: model.id,
                name: model.name,
                description: model.description,
                modified: model.updated_at,
              }}
              open={openConfirmDeleteDialog}
              handleOpen={handleOpenConfirmDeleteDialog}
              handleClose={handleCloseConfirmDeleteDialog}
            />
          )}
          <InviteFormDialog
            open={openInviteFormDialog}
            showTrigger={false}
            to="project"
            handleClose={handleCloseInviteFormDialog}
            handleOpen={handleOpenInviteFormDialog}
            roles={[
              {
                label: 'Project Owner',
                value: 'project_owner',
              },
              {
                label: 'Project Member',
                value: 'project_member',
              },
            ]}
          />
        </div>
      ) : (
        <div
          onClick={() => handleSelect(projectId)}
          className="text-gray-400 text-start"
        >
          --
        </div>
      );
    },
    header: () => <div>Created by</div>,
  }),
];

const DefaultModelsTable = () => {
  const [openCreateModelDialog, setOpenCreateModelDialog] = useState(false);
  const [data, setData] = useState<DataRow[]>([]);

  const { project_id, org_id } = useParams();

  const form = useForm();

  const navigate = useNavigate();

  const projectId = Number(project_id);
  const orgId = Number(org_id);

  const {
    data: models,
    isFetching: isFetchingModels,
    isRefetching: isRefetchingModels,
  } = useGetModels(
    {
      projectId,
      orgId,
    },
    {
      enabled: !!projectId && !!orgId,
    }
  );

  useEffect(() => {
    if (models) {
      setData(
        models.map((r) => ({
          id: r.id,
          name: r.name,
          description: r.description,
          dataset: r.dataset?.toString() ?? '',
          lastModified: '2021/09/01 Joe',
          createdBy: 'Joe',
        })) as DataRow[]
      );
    }
  }, [models]);

  const handleSelect = (id: number) => {
    navigate(`${id}`);
  };

  const table = useReactTable({
    data,
    columns: columns({ handleSelect }),
    getCoreRowModel: getCoreRowModel(),
  });

  const handleOpenCreateModelDialog = () => {
    setOpenCreateModelDialog(true);
  };

  const handleCloseCreateModelDialog = () => {
    setOpenCreateModelDialog(false);
  };

  return (
    <div className="flex flex-col gap-6">
      <h1 className="text-2xl font-bold">Models</h1>
      <FormProvider {...form}>
        <form className="flex justify-between">
          <div className="flex w-[300px] gap-2">
            <SearchInput />
          </div>
          <Button
            variant="contained"
            onClick={handleOpenCreateModelDialog}
            style={{
              backgroundColor: '#B8341B',
              textTransform: 'capitalize',
            }}
          >
            <div className="flex items-center gap-2">
              <Plus width={20} height={20} color="#E8F2FF" />
              New
            </div>
          </Button>
          <CreateModelFormDialog
            title="Create New Model"
            open={openCreateModelDialog}
            handleOpenDialog={handleOpenCreateModelDialog}
            handleCloseDialog={handleCloseCreateModelDialog}
          />
        </form>
      </FormProvider>
      <div className="w-full">
        <table className="w-full font-normal">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className="!rounded-md border-[#E4E7EC] bg-[#F8F8F8] first:border-l last:border-r"
              >
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={cn(
                      'first:border-l border-t border-b last:border-r border-[#E4E7EC] px-6 py-3 text-start text-sm text-[#4D4D4D]'
                    )}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="relative h-full">
            {(isRefetchingModels || isFetchingModels) && (
              <div className="bg-gray-200 absolute flex h-full w-full items-center justify-center opacity-50">
                <Spinner />
              </div>
            )}
            {table.getRowModel().rows ? (
              table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className="cursor-pointer px-6 py-3 transition"
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="border-b border-[#E4E7EC] px-6 text-[#333] first:border-l last:border-r"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <div className="flex justify-center border border-t-0 border-[#E4E7EC] py-20">
                <p className="text-lg text-[#999]">
                  No sharing requests available
                </p>
              </div>
            )}
          </tbody>
        </table>
        <div className="h-4" />
      </div>
    </div>
  );
};

export default DefaultModelsTable;
