import React, { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Collapse } from '@mui/material';

import { useGetModelById } from '../../hooks';
import { useGetPreDefinedExpenses } from '../../hooks/useExpenseHook';
import cn from '../../utils/cn';

type ExpenseCollapseProps = {
  title: string;
  items: {
    label: string;
    value: string;
  }[];
};

export const ExpenseCollapse = ({ title, items }: ExpenseCollapseProps) => {
  const [open, setOpen] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const { org_id, project_id, model_id } = useParams();

  const modelId = Number(model_id);
  const expenseId = searchParams.get('expense_id');
  const orgId = Number(org_id);
  const projectId = Number(project_id);

  const { data: model } = useGetModelById(
    {
      orgId,
      modelId,
      projectId,
    },
    {
      enabled: !!modelId && !!orgId && !!projectId,
    }
  );

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleSelect = (id: number) => {
    searchParams.set('expense_id', id.toString());
    searchParams.delete('mode');

    setSearchParams(searchParams);
  };

  const preDefinedExpenses = model?.predefined_expenses;

  return (
    <div>
      <button
        type="button"
        onClick={handleToggle}
        className="flex items-center gap-1"
      >
        {open ? <ArrowDropUp /> : <ArrowDropDown />}
        <label className="font-bold capitalize">{title}</label>
      </button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className="border-gray-300 mb-4 ml-[11px] mt-2 flex flex-col overflow-y-hidden border-l pl-4">
          {preDefinedExpenses &&
            items
              .filter((i) => preDefinedExpenses.includes(Number(i.value)))
              .map((e: any) => (
                <div key={e.value} className="flex justify-between">
                  <div
                    className={cn(
                      'flex transition w-full items-center gap-1 rounded-[4px]',
                      {
                        'bg-[#21438C]': Number(e.value) === Number(expenseId),
                      }
                    )}
                  >
                    <button
                      className="w-full p-2 text-start"
                      onClick={() => handleSelect(e.value)}
                    >
                      {e.label}
                    </button>
                  </div>
                </div>
              ))}
        </div>
      </Collapse>
    </div>
  );
};

const ExpensesSectionCollapse = () => {
  const { org_id } = useParams();

  const orgId = Number(org_id);

  const { data: predefinedExpenseSets } = useGetPreDefinedExpenses(
    {
      orgId,
    },
    {
      enabled: !!org_id,
    }
  );

  const SalaryExpenses = predefinedExpenseSets
    ?.filter((e) => e.group.id === 1)
    .map((e) => ({
      label: e.name,
      value: e.id.toString(),
    }));

  const BenefitsExpenses = predefinedExpenseSets
    ?.filter((e) => e.group.id === 2)
    .map((e) => ({
      label: e.name,
      value: e.id.toString(),
    }));

  const TaxesExpenses = predefinedExpenseSets
    ?.filter((e) => e.group.id === 3)
    .map((e) => ({
      label: e.name,
      value: e.id.toString(),
    }));

  const TravelExpenses = predefinedExpenseSets
    ?.filter((e) => e.group.id === 4)
    .map((e) => ({
      label: e.name,
      value: e.id.toString(),
    }));

  const Training = predefinedExpenseSets
    ?.filter((e) => e.group.id === 5)
    .map((e) => ({
      label: e.name,
      value: e.id.toString(),
    }));

  const RetirementBenefits = predefinedExpenseSets
    ?.filter((e) => e.group.id === 6)
    .map((e) => ({
      label: e.name,
      value: e.id.toString(),
    }));

  const Operations = predefinedExpenseSets
    ?.filter((e) => e.group.id === 7)
    .map((e) => ({
      label: e.name,
      value: e.id.toString(),
    }));

  return (
    <div>
      <div className="py-2">
        {SalaryExpenses && (
          <ExpenseCollapse title="Salary" items={SalaryExpenses} />
        )}
        {BenefitsExpenses && (
          <ExpenseCollapse title="Benefits" items={BenefitsExpenses} />
        )}
        {TaxesExpenses && (
          <ExpenseCollapse title="Taxes" items={TaxesExpenses} />
        )}
        {TravelExpenses && (
          <ExpenseCollapse title="Travel Expenses" items={TravelExpenses} />
        )}
        {Training && <ExpenseCollapse title="Training" items={Training} />}
        {RetirementBenefits && (
          <ExpenseCollapse
            title="Retirement Benefits"
            items={RetirementBenefits}
          />
        )}
        {Operations && (
          <ExpenseCollapse title="Operations" items={Operations} />
        )}
      </div>
    </div>
  );
};

const ExpenseTypeSection = ({
  type = 'current',
}: {
  type: 'shared' | 'current' | 'received';
}) => {
  const [openCollapse, setOpenCollapse] = useState(true);

  const handleToggleCurrentModel = () => {
    setOpenCollapse(!openCollapse);
  };

  return (
    <>
      <div className="flex flex-col gap-2">
        <button
          onClick={handleToggleCurrentModel}
          className="flex items-center gap-1 text-[#E8F2FF]"
        >
          {openCollapse ? <ArrowDropUp /> : <ArrowDropDown />}
          <p className="capitalize">{type}</p>
        </button>
        <ExpensesSectionCollapse />
      </div>
    </>
  );
};

const ExpenseDrawerSection = () => (
  <div className="flex flex-col gap-4 border-t border-[#6A88BB] px-3 py-6">
    <ExpenseTypeSection type="current" />
  </div>
);

export default ExpenseDrawerSection;
