import { mainApi } from '../utils/api';

export type GetExpenseSetsInput = {
  orgId: number;
  projectId: number;
  datasetId: number;
};

export type GetExpenseSetByIdInput = {
  orgId: number;
  projectId: number;
  expenseSetId: number;
};

export type UpdateExpenseSetInput = {
  orgId: number;
  projectId: number;
  expenseSetId: number;
  data: {
    name: string;
    description?: string;
    dataset: number;
    predefined_expenses?: number[];
    userdefined_expenses?: number[];
  };
};

export type GetPreDefinedExpensesInput = {
  orgId: number;
};

export type GetPreDefinedExpenseByIdInput = {
  orgId: number;
  expenseId: number;
};

export type GetUserDefinedExpensesInput = {
  orgId: number;
  projectId: number;
};

export type GetUserDefinedExpenseByIdInput = {
  orgId: number;
  projectId: number;
  expenseSetId: number;
};

export type CreateUserDefinedExpenseInput = {
  orgId: number;
  projectId: number;
  data: {
    name: string;
    description: string;
    formula: string;
  };
};

export type UpdateUserDefinedExpenseInput = {
  orgId: number;
  projectId: number;
  expenseSetId: number;
  data: {
    name: string;
    description: string;
    formula: string;
  };
};

export type DeleteUserDefinedExpenseInput = {
  orgId: number;
  projectId: number;
  expenseSetId: number;
};

const getPreDefinedExpenses = ({ orgId }: GetPreDefinedExpensesInput) =>
  mainApi.get(`organizations/${orgId}/predefined_expenses/`);

const getPreDefinedExpenseById = ({
  orgId,
  expenseId,
}: GetPreDefinedExpenseByIdInput) =>
  mainApi.get(`organizations/${orgId}/predefined_expenses/${expenseId}/`);

const getUserDefinedExpenses = ({
  orgId,
  projectId,
}: GetUserDefinedExpensesInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/userdefined_expenses/`
  );

const getUserDefinedExpenseById = ({
  orgId,
  projectId,
  expenseSetId,
}: GetUserDefinedExpenseByIdInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/userdefined_expenses/${expenseSetId}/`
  );

const updateUserDefinedExpense = ({
  orgId,
  projectId,
  expenseSetId,
  data,
}: UpdateUserDefinedExpenseInput) =>
  mainApi.put(
    `organizations/${orgId}/projects/${projectId}/userdefined_expenses/${expenseSetId}/`,
    {
      data,
    }
  );

const createUserDefinedExpense = ({
  orgId,
  projectId,
  data,
}: CreateUserDefinedExpenseInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/userdefined_expenses/`,
    { data }
  );

const deleteUserDefinedExpense = ({
  orgId,
  projectId,
  expenseSetId,
}: DeleteUserDefinedExpenseInput) =>
  mainApi.delete(
    `organizations/${orgId}/projects/${projectId}/userdefined_expenses/${expenseSetId}/`
  );

export {
  createUserDefinedExpense,
  deleteUserDefinedExpense,
  getPreDefinedExpenseById,
  getPreDefinedExpenses,
  getUserDefinedExpenseById,
  getUserDefinedExpenses,
  updateUserDefinedExpense,
};
