import React, { useEffect, useState } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Alert, Button } from '@mui/material';
import * as z from 'zod';

import { type TypeProject } from '../../common/dataTypes';
import { useCreateProject } from '../../hooks';
import { useUpdateProject } from '../../hooks/useProjectsHook';
import { handleQueryError } from '../../utils/api';
import FormInput from '../FormInput';

const CreateProjectSchema = z.object({
  name: z.string().trim().trim().min(1, 'Project name is required'),
  description: z.string().trim().optional(),
});

type CreateProjectSchemaType = z.infer<typeof CreateProjectSchema>;

type CreateProjectFormProps = {
  handleOnCancel?: () => void;
  handleOnSubmit?: (data: TypeProject) => void;
  defaultValues?: CreateProjectSchemaType & {
    id: number;
  };
};

const CreateProjectForm = ({
  handleOnSubmit,
  handleOnCancel,
  defaultValues,
}: CreateProjectFormProps) => {
  const [error, setError] = useState(Object);

  const { org_id } = useParams();

  const orgId = Number(org_id);

  const form = useForm<CreateProjectSchemaType>({
    resolver: zodResolver(CreateProjectSchema),
    defaultValues,
  });

  const { handleSubmit, reset } = form;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const { mutateAsync: createProject, isPending: isCreatingProject } =
    useCreateProject({
      onSuccess(data) {
        handleOnSubmit?.(data);
      },
      onError: (error) => {
        handleQueryError(error, setError);
      },
    });

  const { mutateAsync: updateProject, isPending: isUpdatingProject } =
    useUpdateProject({
      onSuccess(data) {
        handleOnSubmit?.(data);
      },
      onError: (error) => {
        handleQueryError(error, setError);
      },
    });

  const handleCreateProject: SubmitHandler<CreateProjectSchemaType> = async (
    data
  ) => {
    if (!orgId) {
      return;
    }

    if (defaultValues) {
      await updateProject({
        projectId: defaultValues.id,
        orgId,
        data,
      });

      return;
    }

    await createProject({
      orgId,
      data: {
        ...data,
        organization: orgId,
      },
    });
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={handleSubmit(handleCreateProject)}
        className="max-w-lg space-y-4 px-6 pb-4"
      >
        <FormInput
          label="Name"
          fullWidth
          inputProps={{
            maxLength: 100,
          }}
          name="name"
        />
        <FormInput
          label="Description"
          fullWidth
          multiline
          rows={4}
          placeholder="(Optional)"
          name="description"
        />
        {typeof error.detail === 'string' && (
          <Alert severity="error">{error.detail}</Alert>
        )}
        <div className="flex justify-end gap-4">
          <Button
            onClick={handleOnCancel}
            variant="outlined"
            style={{
              color: '#666',
              borderColor: '#B3B3B3',
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isCreatingProject ?? isUpdatingProject}
            type="submit"
            variant="contained"
          >
            {defaultValues ? 'Save' : 'Create project'}
          </LoadingButton>
        </div>
      </form>
    </FormProvider>
  );
};

export default CreateProjectForm;
