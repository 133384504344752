import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useDebounce } from '@uidotdev/usehooks';
import _ from 'lodash';

import { type TypeProject } from '../../common/dataTypes';
import { useGetProjects } from '../../hooks';
import { useCurrentMembership } from '../../utils/helpers';
import LastModifiedFilter from '../LastModifiedFilter';
import SearchInput from '../SearchInput';

import ProjectsTable from './ProjectsTable';

const ProjectsView = () => {
  const [searchResults, setSearchResults] = useState<TypeProject[]>([]);

  const membership = useCurrentMembership();
  const { org_id } = useParams();

  const orgId = Number(org_id);

  const { data: projects } = useGetProjects(
    {
      orgId,
    },
    {
      enabled: !!orgId,
    }
  );

  const form = useForm();

  const { watch } = form;

  const debouncedSearchTerm = useDebounce(watch('search'), 300);

  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(`/${orgId}/projects/create`);
  };

  useEffect(() => {
    if (!!debouncedSearchTerm?.length && projects) {
      const filteredProjects = projects.filter(
        (project) =>
          _.includes(
            project.name.trim().toLowerCase(),
            debouncedSearchTerm.trim().toLowerCase()
          ) ||
          _.includes(
            project.description.trim().toLowerCase(),
            debouncedSearchTerm.trim().toLowerCase()
          )
      );

      setSearchResults(filteredProjects);
      return;
    }

    setSearchResults([]);
  }, [debouncedSearchTerm, projects]);

  return (
    <div className="mx-auto w-full max-w-lg px-12 lg:px-0">
      <div className="flex flex-col gap-6 py-6">
        <h1 className="text-2xl font-bold">Projects</h1>
        <div className="flex items-center justify-between">
          <FormProvider {...form}>
            <form className="flex items-center gap-3">
              <div className="min-w-[300px]">
                <SearchInput />
              </div>
              <LastModifiedFilter />
            </form>
          </FormProvider>
          {membership?.organization?.role === 'organization_admin' && (
            <Button
              type="button"
              variant="contained"
              style={{
                backgroundColor: '#B8341B',
                textTransform: 'capitalize',
              }}
              onClick={handleOnClick}
              className="text-white flex items-center gap-2 rounded-[4px] bg-primary-red px-3 py-2"
            >
              <Add
                style={{
                  height: '22px',
                  width: '22px',
                }}
              />
              New Project
            </Button>
          )}
        </div>
        <ProjectsTable defaultProjects={searchResults} />
      </div>
    </div>
  );
};

export default ProjectsView;
