import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';

import { Button } from '@mui/material';
import {
  type ColumnsStylesInterface,
  DataGridPremium,
  type GridColDef,
  type GridColumnGroupingModel,
  type GridExcelExportOptions,
  type GridPinnedColumns,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { ArrowDownToLine } from 'lucide-react';

import {
  getColsFromRow,
  getGroupByColumns,
  getSupportedPivotDisplayModes,
} from '../../common/dataTableFormatter';
import { useGetDatasetById } from '../../hooks';
import { useGetDataTableByName } from '../../hooks/useDataTablesHook';
import {
  useGetScenarioById,
  useGetScenarioData,
  useGetScenariosByDataTableName,
} from '../../hooks/useScenariosHook';
import { type Granularity } from '../../types';
import { useCurrentProject } from '../../utils/helpers';
import FormSelect from '../FormSelect';
import VectorsSection from '../scenario/VectorsSection';
import CreateVectorFormDialog from '../vector/CreateVectorFormDialog';

const ModelScenarioDetailView = () => {
  const currentProject = useCurrentProject();
  const { org_id, project_id, input_table_name, model_id } = useParams();

  const apiRef = useGridApiRef();

  const [searchParams] = useSearchParams();

  const orgId = Number(org_id);
  const projectId = Number(project_id);
  const modelId = Number(model_id);

  const [dataTableCols, setDataTableCols] = useState<GridColDef[]>([]);
  const [dataTableRows, setDataTableRows] = useState<Object[]>([]);

  const [openDialogCreateVector, setOpenDialogCreateVector] = useState(false);

  const [dataTableColumnGroupingModel, setDataTableColumnGroupingModel] =
    useState<GridColumnGroupingModel>([]);

  const [dataTablePinnedColumns, setDataTablePinnedColumns] =
    useState<GridPinnedColumns>();

  const scenarioId = Number(searchParams.get('scenario_id'));
  const datasetId = Number(searchParams.get('dataset_id'));

  const form = useForm({
    defaultValues: {
      timeline: 'yearly',
      aggregateColumn: '',
    },
  });

  const { watch, setValue } = form;

  const selectedColumn = watch('aggregateColumn');
  const selectedTimeline = watch('timeline');

  const { data: dataTable } = useGetDataTableByName(
    {
      dataTableName: input_table_name ?? '',
      orgId,
      projectId,
    },
    {
      enabled: !!input_table_name && !!orgId && !!projectId,
    }
  );

  const { data: scenarios } = useGetScenariosByDataTableName(
    {
      dataTableName: input_table_name ?? '',
      orgId,
      projectId,
      modelId,
    },
    {
      enabled: !!input_table_name && !!orgId && !!projectId && !!modelId,
    }
  );

  const baseScenario = scenarios?.find((m) => m.is_base);

  const { data: scenario } = useGetScenarioById(
    {
      scenarioId,
      orgId,
      projectId,
      modelId,
    },
    {
      enabled: !!scenarioId && !!orgId && !!projectId && !!modelId,
    }
  );

  const { data: dataset } = useGetDatasetById(
    {
      datasetId,
      orgId,
      projectId,
    },
    {
      enabled: !!datasetId && !!orgId && !!projectId,
    }
  );

  const { data: baseScenarioData, isPending: isFetchingBaseScenarioData } =
    useGetScenarioData(
      {
        scenarioId: baseScenario?.id ?? NaN,
        orgId,
        modelId,
        projectId,
        dataName: '',
      },
      {
        enabled: !!baseScenario?.id && !!orgId && !!projectId && !!modelId,
      }
    );

  const {
    data: mainScenarioData,
    isPending: isGenerateScenarioData,
    refetch: refetchGenerateScenarioData,
  } = useGetScenarioData(
    {
      scenarioId,
      orgId,
      modelId,
      projectId,
      dataName: '',
    },
    {
      enabled: !!scenarioId && !!orgId && !!projectId && !!modelId,
    }
  );

  const aggColumns = useMemo(
    () =>
      dataTable?.pivot?.aggregate_columns.map((c) => ({
        label: c,
        value: c,
      })) ?? [],
    [dataTable]
  );

  const handleExportToExcel = () => {
    const colStyles: ColumnsStylesInterface = {};
    dataTableCols.forEach((colDef) => {
      colStyles[colDef.field] = {
        numFmt: '#,##0.00',
        font: {},
        alignment: {},
        protection: {},
        border: {},
        fill: {
          type: 'pattern',
          pattern: 'none',
        },
      };
    });

    const options: GridExcelExportOptions = {
      fileName: getExportFileName(),
      columnsStyles: { ...colStyles },
    };

    apiRef.current.exportDataAsExcel(options);
  };

  const getExportFileName = () => {
    const datasetName = dataset ? dataset.name : 'dataset_unknown';

    const dataTableName = dataTable ? dataTable.name : 'table_unknown';

    const scenarioName = scenario ? scenario.name : 'scenario_unknown';

    return datasetName + '_' + dataTableName + '_' + scenarioName;
  };

  useEffect(() => {
    if (aggColumns.length > 0) {
      setValue('aggregateColumn', aggColumns[0].value, {
        shouldDirty: false,
        shouldValidate: false,
      });
    }
  }, [aggColumns]);

  useEffect(() => {
    refetchGenerateScenarioData();
  }, [
    selectedColumn,
    scenarioId,
    baseScenarioData,
    selectedTimeline,
    dataTable,
  ]);

  useEffect(() => {
    const colSpec: any[] = [];

    if (!scenario || !dataTable || !mainScenarioData) {
      return;
    }

    const availableDisplayModes = getSupportedPivotDisplayModes(dataTable);

    const granularity = watch('timeline') as Granularity;

    const { cols, colGroupingModel } = getColsFromRow(
      mainScenarioData[0],
      dataTable,
      granularity,
      baseScenarioData ?? (mainScenarioData as any[]),
      colSpec
    );

    setDataTableCols(cols);
    setDataTableRows(mainScenarioData);

    let pinnedCols: string[] = [];

    if (availableDisplayModes.length > 0) {
      pinnedCols = getGroupByColumns(dataTable) ?? [];
    }

    setDataTableColumnGroupingModel(colGroupingModel);
    setDataTablePinnedColumns({ left: pinnedCols });
  }, [mainScenarioData]);

  const isGuestProject = currentProject?.is_guest_project;

  return (
    <FormProvider {...form}>
      <form>
        <div className="flex flex-col space-y-6 px-12">
          <h1 className="text-2xl font-bold">{scenario?.name}</h1>
          {!scenario?.is_base && (
            <div className="flex flex-col gap-3 rounded-[4px] border border-[#E4E7EC] bg-[#FFF] px-6 py-4">
              <h2 className="font-bold">Description</h2>
              <p>{scenario?.description}</p>
            </div>
          )}
          {!scenario?.is_base && !isGuestProject && (
            <div className="space-y-3">
              <div className="flex justify-between">
                <h2 className="text-xl font-bold">Vectors</h2>
                <CreateVectorFormDialog
                  open={openDialogCreateVector}
                  title="Add new vector"
                  handleCloseDialog={() => {
                    setOpenDialogCreateVector(false);
                  }}
                  handleOpenDialog={() => {
                    setOpenDialogCreateVector(true);
                  }}
                />
              </div>
              <VectorsSection />
            </div>
          )}
          <div className="space-y-2">
            <h2 className="text-xl font-bold">Data</h2>
            <div className="flex items-end justify-between">
              <div className="flex w-[25%] gap-3">
                <FormSelect
                  name="aggregateColumn"
                  fullWidth
                  options={aggColumns}
                  defaultValue={aggColumns[0]?.value}
                  label="Aggregate Column"
                />
              </div>
              <div>
                <Button
                  onClick={handleExportToExcel}
                  variant="outlined"
                  className="space-x-2"
                >
                  <ArrowDownToLine />
                  <label className="font-semibold capitalize">
                    Export to Excel
                  </label>
                </Button>
              </div>
            </div>
            <div className="h-[380px] py-6">
              <DataGridPremium
                apiRef={apiRef}
                rows={dataTableRows}
                columns={dataTableCols}
                pagination={false}
                disableSelectionOnClick
                loading={isGenerateScenarioData || isFetchingBaseScenarioData}
                experimentalFeatures={{ columnGrouping: true }}
                columnGroupingModel={dataTableColumnGroupingModel}
                density="compact"
                pinnedColumns={dataTablePinnedColumns}
                onPinnedColumnsChange={(pinnedColumns) =>
                  setDataTablePinnedColumns(pinnedColumns)
                }
              />
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default ModelScenarioDetailView;
