import React, { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { Button, Dialog } from '@mui/material';
import { Trash2 } from 'lucide-react';

import { toastPromise } from '../../common/utils';
import {
  useGetOrgInvitationById,
  useGetOrgMemberById,
} from '../../hooks/useOrganizationHook';
import { useDeleteProjectMember } from '../../hooks/useProjectsHook';
import { useGetUser } from '../../hooks/useUserHook';

type ConfirmDeleteProjectDialogProps = {
  project: {
    id: number;
    name: string;
  };
};

const ConfirmDeleteProjectDialog = ({
  project,
}: ConfirmDeleteProjectDialogProps) => {
  const [open, setOpen] = useState(false);
  const { user_id, org_id } = useParams();
  const [searchParams] = useSearchParams();

  const userId = Number(user_id);
  const orgId = Number(org_id);

  const type = searchParams.get('type');

  const { mutateAsync: deleteProjectMember } = useDeleteProjectMember();

  const { data: member } = useGetOrgMemberById(
    {
      id: orgId,
      memberId: userId,
    },
    {
      enabled: !!userId && !!orgId,
    }
  );

  const { data: invitation } = useGetOrgInvitationById(
    {
      id: orgId,
      invitationId: userId,
    },
    {
      enabled: !!orgId && !!userId,
    }
  );

  const projectMember = member?.project_memberships.find(
    (p) => p.project.id === project.id
  );

  const projectInvitation = invitation?.projects.find(
    (p) => p.id === project.id
  );

  const { data: currentUser } = useGetUser();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async () => {
    if (!orgId || !projectMember) {
      return;
    }

    let res = null;

    if (type === 'member') {
      res = deleteProjectMember({
        orgId,
        projectId: project.id,
        memberId: projectMember.id,
      });
    }

    if (res) {
      toastPromise({
        promise: res,
        content: 'User has been deleted successfully',
      });
    }
  };

  return (
    <>
      <button type="button" onClick={handleOpen}>
        <Trash2 color="#666666" width={20} height={20} />
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alertTitle"
        aria-describedby="alertContent"
      >
        <div className="flex flex-col gap-6 p-6">
          <h2 className="text-xl font-bold">
            {member?.user.id === currentUser?.id
              ? 'Are you sure you want to leave this project?'
              : `Are you sure you want to delete this user from ${project.name}?`}
          </h2>
          <div className="flex justify-end gap-3">
            <Button
              onClick={handleClose}
              variant="outlined"
              tabIndex={3}
              style={{
                color: '#666',
                borderColor: '#B3B3B3',
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              tabIndex={2}
              variant="contained"
              style={{
                backgroundColor: '#B8341B',
                color: '#FFF',
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ConfirmDeleteProjectDialog;
