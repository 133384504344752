import React from 'react';
import { useParams } from 'react-router-dom';

import ScenarioInputTable from '../scenario/ScenarioInputTable';

const ModelScenarioOverview = () => {
  const { model_id, project_id, org_id } = useParams();

  const modelId = Number(model_id);
  const projectId = Number(project_id);
  const orgId = Number(org_id);

  return (
    <div className="flex flex-col justify-between gap-4 pb-3">
      <h2 className="text-3xl font-bold">Scenario</h2>
      <ScenarioInputTable />
    </div>
  );
};

export default ModelScenarioOverview;
