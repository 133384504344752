import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { ArrowBackIosNew } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Pencil } from 'lucide-react';

import { useGetModelById } from '../../hooks';

import CreateModelFormDialog from './CreateModelFormDialog';
import ModelOutputTabs from './ModelOutputTabs';

const ModelOverview = () => {
  const { model_id, project_id, org_id } = useParams();

  const navigate = useNavigate();

  const [enable, setEnable] = useState(false);
  const [openCreateModelDialog, setOpenCreateModelDialog] = useState(false);

  const modelId = Number(model_id);
  const projectId = Number(project_id);
  const orgId = Number(org_id);

  const form = useForm();

  const { handleSubmit } = form;

  const { data: model } = useGetModelById(
    {
      modelId,
      projectId,
      orgId,
    },
    {
      enabled: !!modelId && !!projectId && !!orgId,
    }
  );

  const handleOpenCreateModelDialog = () => {
    setOpenCreateModelDialog(true);
  };

  const handleCloseCreateModelDialog = () => {
    setOpenCreateModelDialog(false);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const onSubmit = (data: any) => {
    setEnable(true);
  };

  return (
    <div className="flex flex-col gap-6 pb-6">
      <div className="flex flex-col gap-3">
        <div className="flex justify-between">
          <div className="flex items-center gap-6">
            <div
              onClick={handleGoBack}
              className="flex items-center gap-4 cursor-pointer"
            >
              <ArrowBackIosNew
                style={{
                  height: '16px',
                  width: '16px',
                }}
              />
              <h1 className="text-2xl font-bold">{model?.name}</h1>
            </div>
            <Pencil
              size={20}
              color="#666666"
              className="cursor-pointer"
              onClick={handleOpenCreateModelDialog}
            />
            {model && (
              <CreateModelFormDialog
                open={openCreateModelDialog}
                title={'Edit model'}
                handleOpenDialog={handleOpenCreateModelDialog}
                handleCloseDialog={handleCloseCreateModelDialog}
                defaultValues={{
                  name: model.name,
                  description: model.description,
                  //....dataset and scenario
                }}
              />
            )}
          </div>
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              // handleShareToHost(Number(modelId));
            }}
            style={{
              color: '#B8341B',
              borderColor: '#B8341B',
              display: 'flex',
              gap: 4,
              borderRadius: 4,
              padding: '6px 12px',
            }}
          >
            Share To Host
          </Button>
        </div>
        <div className="flex flex-col gap-2 border border-[#E4E7EC] px-6 py-4">
          <p className="flex-grow font-bold">Description</p>
          <p>{model?.description}</p>
        </div>
      </div>
      <div className="flex flex-col">
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex items-center justify-between">
              <h2 className="text-3xl font-bold">Overview</h2>
              <Button
                variant="contained"
                type="submit"
                style={{
                  color: '#fff',
                  height: '35px',
                  backgroundColor: '#B8341B',
                }}
              >
                Run
              </Button>
            </div>
            <ModelOutputTabs enable={enable} />
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default ModelOverview;
